import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';
import Pdf from '../siddharth-naidu-cv.pdf';

class Sitemap extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Sitemap | Siddharth Frontend Website Developer with 5 years of Experience</title>
        </Helmet>

        <div className="banner">
            <img src={require('../images/banners/about-me.jpg')} alt="Siddharth Naidu's Sitemap" title="Siddharth Naidu's Sitemap" />
            <Fade bottom duration={500} delay={200}><h3>Sitemap</h3></Fade>
            <Fade bottom duration={500} delay={200}><h4>Listed all pages titles and links here.</h4></Fade>
        </div>

        <div className="sitemap">
            <div className="container">

                <div className="links">
                    <h4>Portfolio</h4>
                    <ul className="navigation">
                        <li><NavLink to="/project/gagana-books">Gagana Books</NavLink></li>
                        <li><NavLink to="/project/armadaa">Armadaa</NavLink></li>
                        <li><NavLink to="/project/equeris">EQueris</NavLink></li>
                        <li><NavLink to="/project/vanismart">Vanismart</NavLink></li>
                        <li><NavLink to="/project/dearadhpro">DearadhPro</NavLink></li>
                        <li><NavLink to="/project/itor">ItoR Automation</NavLink></li>
                        <li><NavLink to="/project/gensuite-csr">Gensuite CSR</NavLink></li>
                        <li><NavLink to="/project/prasad-photography">Prasad Photography</NavLink></li>
                        <li><NavLink to="/project/us-cranberries">US Cranberries</NavLink></li>
                        <li><NavLink to="/project/guru-prasadam">Guru Prasadam</NavLink></li>
                        <li><NavLink to="/project/bull-india">Bull India</NavLink></li>
                        <li><NavLink to="/project/siddharth-naidu">Siddharth Naidu</NavLink></li>
                        <li><NavLink to="/project/elrich-life">Elrich Life</NavLink></li>
                        <li><NavLink to="/project/essae">Essae</NavLink></li>
                        <li><NavLink to="/project/prasadam">Prima Baby</NavLink></li>
                        <li><NavLink to="/project/karlsson-leather">Karlsson Leather</NavLink></li>
                        <li><NavLink to="/project/kuberan-silks">Kuberan Silks</NavLink></li>
                        <li><NavLink to="/project/gensuite">Gensuite</NavLink></li>
                        <li><NavLink to="/project/gensuite-india">Gensuite India</NavLink></li>
                        <li><NavLink to="/project/gensuite-conference">Gensuite Conference</NavLink></li>
                        <li><NavLink to="/project/gearnride">Gear n Ride</NavLink></li>
                        <li><NavLink to="/project/ibca">IBCA</NavLink></li>
                        <li><NavLink to="/project/lexington">Lexington</NavLink></li>
                        <li><NavLink to="/project/abhee-nandana">Abhee Nandana</NavLink></li>
                        <li><NavLink to="/project/online-kingg">Online King</NavLink></li>
                        <li><NavLink to="/project/dequalita">DeQualita</NavLink></li>
                        <li><NavLink to="/project/rajashri-foods">Rajashri Foods</NavLink></li>
                        <li><NavLink to="/project/essae-dig">Essae Dig</NavLink></li>
                        <li><NavLink to="/project/lamppost-media">Lamppost Media</NavLink></li>
                        <li><NavLink to="/project/aroma-milk">Aroma Milk</NavLink></li>
                        <li><NavLink to="/project/tekcel">Tekcel</NavLink></li>
                        <li><NavLink to="/project/mkc-food-products">MKC Food Products</NavLink></li>
                        <li><NavLink to="/project/kirthika-international">Kirthika International</NavLink></li>
                        <li><NavLink to="/project/renaatus-projects">Renaatus Projects</NavLink></li>
                        <li><NavLink to="/project/saideep-exports">Saideep Exports</NavLink></li>
                        <li><NavLink to="/project/incite-cam-centre">Incite Cam Centre</NavLink></li>
                        <li><NavLink to="/project/kargil-equipments">Kargil Equipments</NavLink></li>
                        <li><NavLink to="/project/sandisol">Sandisol</NavLink></li>
                        <li><NavLink to="/project/secuserve">Secuserve</NavLink></li>
                        <li><NavLink to="/project/the-talk">The Talk</NavLink></li>
                        <li><NavLink to="/project/tingg-ideas">Tingg Ideas</NavLink></li>
                        <li><NavLink to="/project/uv-gullas">UV Gullas</NavLink></li>
                        <li><NavLink to="/project/appac-media">Appac Media</NavLink></li>
                        <li><NavLink to="/project/glittek-granites">Glittek Granites</NavLink></li>
                        <li><NavLink to="/project/msrmh">MSRMH</NavLink></li>
                        <li><NavLink to="/project/igauge">iGauge</NavLink></li>
                        <li><NavLink to="/project/ms-pink-panther">MS Pink Panther</NavLink></li>
                        <li><NavLink to="/project/nehas-bags">Nehas Bags</NavLink></li>
                        <li><NavLink to="/project/floret-media">Floret Media</NavLink></li>
                        <li><NavLink to="/project/alankar-logistics">Alankar Logistics</NavLink></li>
                        <li><NavLink to="/project/unigauge">UniGauge</NavLink></li>
                        <li><NavLink to="/project/utcom">UTCOM</NavLink></li>
                        <li><NavLink to="/project/comet-metallic">Comet Metallic</NavLink></li>
                        <li><NavLink to="/project/dr-venus">Dr.Venus</NavLink></li>
                        <li><NavLink to="/project/geewin-exim">Geewin Exim</NavLink></li>
                        <li><NavLink to="/project/gmg-advisors">GMG Advisors</NavLink></li>
                        <li><NavLink to="/project/iyengars-bakery">Iyengars Bakery</NavLink></li>
                        <li><NavLink to="/project/caripill-micro">Caripill Micro</NavLink></li>
                        <li><NavLink to="/project/ecakoholic">ECakoholic</NavLink></li>
                        <li><NavLink to="/project/digital-exchange">Digital Exchange</NavLink></li>
                        <li><NavLink to="/project/mta-live-events">MTA Live Events</NavLink></li>
                        <li><NavLink to="/project/pjt-foods">PJT Foods</NavLink></li>
                        <li><NavLink to="/project/propel">Propel</NavLink></li>
                        <li><NavLink to="/project/dutyx">DutyX</NavLink></li>
                        <li><NavLink to="/project/ps-consultants">PS Consultants</NavLink></li>
                        <li><NavLink to="/project/snm-masala">SNM Masala</NavLink></li>
                        <li><NavLink to="/project/mr-intelligent">Mr.Intelligent</NavLink></li>
                        <li><NavLink to="/project/namasthe-andhra">Namasthe Andhra</NavLink></li>
                        <li><NavLink to="/project/cbc-fashions">CBC Fashions</NavLink></li>
                        <li><NavLink to="/project/1mg-lido-mall">1MG Lido Mall</NavLink></li>
                        <li><NavLink to="/project/pavechas">Pavechas</NavLink></li>
                        <li><NavLink to="/project/sam-pumps">Sam Pumps</NavLink></li>
                        <li><NavLink to="/project/thrayi">Thrayi</NavLink></li>
                        <li><NavLink to="/project/tina-kamal">Tina Kamal</NavLink></li>
                        <li><NavLink to="/project/archies-projects">Archies Projects</NavLink></li>
                        <li><NavLink to="/project/yaseen-overseas">Yaseen Overseas</NavLink></li>
                        <li><NavLink to="/project/bangalore-rmc">Bangalore RMC</NavLink></li>                    
                        <li><NavLink to="/project/digiqom">Digiqom</NavLink></li>
                        <li><NavLink to="/project/rentozy">Rentozy</NavLink></li>
                    </ul>
                </div>

                <div className="links">
                    <h4>Useful Links</h4>
                    <ul className="navigation">
                        <li><NavLink to="">Home</NavLink></li>
                        <li><NavLink to="/about">About me</NavLink></li>
                        <li><NavLink to={Pdf} target="_blank">Resume</NavLink></li>
                        <li><NavLink to="/portfolio">Portfolio</NavLink></li>
                        <li><NavLink to="/design">Web & Graphic</NavLink></li>
                        <li><NavLink to="/apps">Mobile Apps</NavLink></li>
                        <li><NavLink to="/photos">Photos</NavLink></li>
                        <li><NavLink to="/blog">Blog</NavLink></li>
                        <li><NavLink to="/contact">Contact</NavLink></li>
                    </ul>
                    <h4>Hobbies</h4>
                    <ul>
                        <li><NavLink to="/photos">Travelling</NavLink></li>
                        <li><NavLink to="/hobbie/photography">Photography</NavLink></li>
                        <li><NavLink to="/hobbie/cooking">Cooking</NavLink></li>
                        <li><NavLink to="/hobbie/movies">Nextfilx Movies</NavLink></li>
                    </ul>
                    <h4>Trips</h4>
                    <ul>
                        <li><NavLink to="/trip/mysuru">Mysuru</NavLink></li>
                        <li><NavLink to="/trip/amaravati">Amaravati</NavLink></li>
                        <li><NavLink to="/trip/guntur">Guntur</NavLink></li>
                        <li><NavLink to="/trip/nandi-hills">Nandi Hills</NavLink></li>
                        <li><NavLink to="/trip/tirupati">Tirupati</NavLink></li>
                        <li><NavLink to="/trip/vijayawada">Vijayawada</NavLink></li>
                        <li><NavLink to="/trip/coorg">Coorg</NavLink></li>
                        <li><NavLink to="/trip/sakleshpur">Sakleshpur</NavLink></li>
                        <li><NavLink to="/trip/bannerghatta">Bannerghatta</NavLink></li>
                        <li><NavLink to="/trip/chikmagalur">Chikmagalur</NavLink></li>
                        <li><NavLink to="/trip/big-banyan-tree">Big Banyan Tree</NavLink></li>
                        <li><NavLink to="/trip/vidhana-soudha">Vidhana Soudha</NavLink></li>
                        <li><NavLink to="/trip/ooty">Ooty</NavLink></li>
                        <li><NavLink to="/trip/wonderla">Wonderla</NavLink></li>
                        <li><NavLink to="/trip/palamaner">Palamaner</NavLink></li>
                        <li><NavLink to="/trip/yelahanka">Yelahanka</NavLink></li>
                        <li><NavLink to="/trip/nandi-hills-2017">Nandi Hills 2017</NavLink></li>
                        <li><NavLink to="/trip/guntur-2016">Guntur</NavLink></li>
                        <li><NavLink to="/trip/salem">Salem</NavLink></li>
                    </ul>
                    <h4>Socialmedia</h4>
                    <ul className="followme">
                        <li><a href="https://www.facebook.com/SiddharthNaidu.Pagadala/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i> SiddharthNaidu.Pagadala</a></li>
                        <li><a href="https://www.instagram.com/SiddharthNaidu.Pagadala/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i> SiddharthNaidu.Pagadala</a></li>
                        <li><a href="https://twitter.com/Siddharth_PGDLA/" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i> Siddharth_PGDLA</a></li>
                        <li><a href="https://in.pinterest.com/SiddharthNaiduPagadala/_saved/" target="_blank"><i class="fa fa-pinterest" aria-hidden="true"></i> SiddharthNaiduPagadala</a></li>
                        <li><a href="https://www.linkedin.com/in/SiddharthNaidu-PGDLA/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i> SiddharthNaidu-PGDLA</a></li>
                        <li><a href="https://www.behance.net/siddharth_naidu/" target="_blank"><i class="fa fa-behance" aria-hidden="true"></i> siddharth_naidu</a></li>
                        <li><a href="https://soundcloud.com/siddharth_naidu/" target="_blank"><i class="fa fa-soundcloud" aria-hidden="true"></i> siddharth_naidu</a></li>
                        <li><a href="https://wa.me/+919948400800"><i class="fa fa-whatsapp" aria-hidden="true"></i> 9948400800</a></li>
                    </ul>
                </div>              

            </div>
        </div>

      </div>
    )
  }

}

export default Sitemap;
