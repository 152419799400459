import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';

class Cooking extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Cooking | Siddharth's Hobbies</title>
        </Helmet>

        <div className="banner">
            <img src={require('../images/cooking/egg-curry.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
            <Fade bottom duration={500} delay={200}><h3>Cooking</h3></Fade>
            <Fade bottom duration={500} delay={200}><h4>Perfect for anybody who enjoys eating.</h4></Fade>
        </div>

        <div className="photos">
          <div className="container">

              <div className="mainWrapper">
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/okra-curry.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Okra Curry</h3>
                      </figcaption>
                  </div>
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/potato-tomato-curry.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Potato & Tomato</h3>
                      </figcaption>
                  </div>
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/taro-root-curry.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Taro Root Curry</h3>
                      </figcaption>
                  </div>
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/beans-curry.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Beans Curry</h3>
                      </figcaption>
                  </div>
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/chicken-fry.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Chicken Fry</h3>
                      </figcaption>
                  </div>
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/potato-curry.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Potato Curry</h3>
                      </figcaption>
                  </div>
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/egg-curry.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Egg Curry</h3>
                      </figcaption>
                  </div>
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/potato-fry.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Spicy Potato Fry</h3>
                      </figcaption>
                  </div>
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/chicken-curry.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Chicken Curry</h3>
                      </figcaption>
                  </div>
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/egg-burji.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Egg Bruji</h3>
                      </figcaption>
                  </div>
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/potato-rice.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Potato Rice</h3>
                      </figcaption>
                  </div>
                  <div className="dish">
                      <figure>
                        <img src={require('../images/cooking/tomato-rice.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" />
                      </figure>
                      <figcaption>
                        <h3>Tomato Rice</h3>
                      </figcaption>
                  </div>
                </div>

          </div>
        </div>

      </div>
    )
  }

}

export default Cooking;
