import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';

class Photography extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Photography | Siddharth's Hobbies</title>
        </Helmet>

        <div className="banner">
            <img src={require('../images/banners/mysuru.jpg')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
            <Fade bottom duration={500} delay={200}><h3>Photography</h3></Fade>
            <Fade bottom duration={500} delay={200}><h4>Having fun while capturing memories is my favorite thing.</h4></Fade>
        </div>

        <div className="photos">
          <div className="container">

              <div className="mainWrapper">
                    <img src={require('../images/trips/vidhana-soudha/siddharth-vidhana-soudha-trip-07.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/vidhana-soudha/siddharth-vidhana-soudha-trip-04.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/sakleshpur/siddharth-sakleshpur-trip-09.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" className="full-width" />
                    <img src={require('../images/trips/bannerghatta-zoo/siddharth-bannerghatta-zoo-trip-11.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/bannerghatta-zoo/siddharth-bannerghatta-zoo-trip-01.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/big-banyan-tree/siddharth-big-banyan-tree-trip-01.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/big-banyan-tree/siddharth-big-banyan-tree-trip-04.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/vidhana-soudha/siddharth-vidhana-soudha-trip-02.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" className="full-width" />
                    <img src={require('../images/trips/mysuru/siddharth-mysuru-trip-44.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/mysuru/siddharth-mysuru-trip-03.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/mysuru/siddharth-mysuru-trip-12.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/mysuru/siddharth-mysuru-trip-40.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/mysuru/siddharth-mysuru-trip-27.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/mysuru/siddharth-mysuru-trip-34.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/amaravati/siddharth-amaravati-trip-16.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" className="full-width" />
                    <img src={require('../images/trips/nandi-hills/siddharth-nandi-hills-trip-09.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" className="full-width" />
                    <img src={require('../images/trips/nandi-hills/siddharth-nandi-hills-trip-12.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/nandi-hills/siddharth-nandi-hills-trip-13.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/tirupati/siddharth-tirupati-trip-11.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                    <img src={require('../images/trips/sakleshpur/siddharth-sakleshpur-trip-02.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
              </div>

          </div>



        </div>

      </div>
    )
  }

}

export default Photography;
