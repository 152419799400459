import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Mysuru extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Mysuru Trip</title>
        </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/photos.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            <h3>Mysuru</h3>
            <h4>When i'm not creating websites, i love to travel.</h4>
        </div>

        <div className="photos">
          <div className="container">
              <div className="mainWrapper">
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-01.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-44.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-03.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-04.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-05.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-02.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-06.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-09.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-10.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-12.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-33.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-08.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-13.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-15.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-14.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-16.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-17.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-19.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-20.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-21.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-22.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-23.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-18.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-24.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-26.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-27.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-43.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-25.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-28.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-29.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-30.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-31.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-11.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-34.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-35.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-36.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-38.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-39.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-37.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-40.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-41.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/mysuru/siddharth-mysuru-trip-42.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
              </div>

          </div>

        </div>

      </div>
    )
  }

}

export default Mysuru;
