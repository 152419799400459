import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


class Palamaner extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Palamaner Trip</title>
        </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/photos.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            <h3>Palamaner</h3>
            <h4>When i'm not creating websites, i love to travel.</h4>
        </div>

        <div className="photos">
          <div className="container">

              <div className="mainWrapper">
                    <img src={require('../../images/trips/palamaner/siddharth-palamaner-trip-03.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/palamaner/siddharth-palamaner-trip-04.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/palamaner/siddharth-palamaner-trip-01.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/palamaner/siddharth-palamaner-trip-06.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/palamaner/siddharth-palamaner-trip-05.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/palamaner/siddharth-palamaner-trip-02.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
              </div>

          </div>
        </div>

      </div>
    )
  }

}

export default Palamaner;
