import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { Helmet } from 'react-helmet';

class Blog extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

      <Helmet>
          <title>Blog | Siddharth Naidu's Portfolio</title>
      </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/portfolio.jpg')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
            <h3>Blog | Siddharth Naidu</h3>
            <h4>Articles & Tutorials</h4>
        </div>

        <div className="project-info">
          <div className="container">
              <figure>
                <img src={require('../../images/portfolio/blog.png')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
              </figure>
              <div className="project-details">
                  <h2>Blog | Siddharth Naidu</h2>
                  <h3>Coming soon</h3>
                  <p>building on MEAN stack with user authentication and authorization.</p>
              </div>
              <div className="project-side">
                  <h4>Duration</h4>
                  <p>13 days and counting</p>
                  <h4>Project Type</h4>
                  <p>Blog site</p>
                  <h4>Project Homepage</h4>
                  <p><a href="https://blog.siddharthnaidu.com" target="_blank">blog.siddharthnaidu.com</a></p>
              </div>
          </div>
        </div>

        <div className="project-navigation">
          <div className="container">
              <NavLink to="/project/siddharth-naidu">
                <div className="prev">
                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    <p>Previous</p>
                    <h4>Siddharth Naidu</h4>
                </div>
              </NavLink>
              <NavLink to="/project/prima-baby">
                <div className="next">
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    <p>Next</p>
                    <h4>Prima Baby</h4>
                </div>
              </NavLink>
          </div>
        </div>

      </div>
    )
  }

}

export default Blog;
