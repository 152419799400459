import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Chikmagalur extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Chikmagalur Trip</title>
        </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/photos.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            <h3>Chikmagalur</h3>
            <h4>When i'm not creating websites, i love to travel.</h4>
        </div>

        <div className="photos">
          <div className="container">

            <div className="mainWrapper">
                <img src={require('../../images/trips/chikmagalur/siddharth-chikmagalur-trip-01.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/chikmagalur/siddharth-chikmagalur-trip-04.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/chikmagalur/siddharth-chikmagalur-trip-02.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                <img src={require('../../images/trips/chikmagalur/siddharth-chikmagalur-trip-03.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                <img src={require('../../images/trips/chikmagalur/siddharth-chikmagalur-trip-08.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/chikmagalur/siddharth-chikmagalur-trip-06.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/chikmagalur/siddharth-chikmagalur-trip-07.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/chikmagalur/siddharth-chikmagalur-trip-09.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/chikmagalur/siddharth-chikmagalur-trip-10.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/chikmagalur/siddharth-chikmagalur-trip-11.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            </div>

          </div>
        </div>

      </div>
    )
  }

}

export default Chikmagalur;
