import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import TypeWriterEffect from 'react-typewriter-effect';
import { LightgalleryItem } from "react-lightgallery";

class Home extends React.Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
        <div className="app">

        <Helmet>
            <title>Siddharth Naidu Pagadala | Frontend Website Developer with 6 years of Experience</title>
        </Helmet>

        <div className="avatar">
          <img src={require('../images/siddharth-naidu.png')} alt="Siddharth Naidu Pagadala" title="Siddharth Naidu Pagadala" />
          <div className="container">
              <Fade bottom duration={800} delay={200}><h1>Hi <span className="emoji">👋 </span> I'm          
              <TypeWriterEffect
                startDelay={10}
                cursorColor="#999999"
                multiText={[
                  'Siddharth Naidu',
                  'సిద్ధార్థ్ నాయుడు',
                  'सिद्धार्थ नायडू',
                  'ಸಿದ್ಧಾರ್ಥ್ ನಾಯ್ಡು',
                  'সিদ্ধার্থ নাইডু',
                  'சித்தார்த் நாயுடு',
                  '悉达多·奈杜',
                  'シッダールスナイドゥ',
                  'Сиддхарт Найду',
                  '싯다르트 나이두',
                  'สิทธารถ ไนดู',
                  'Siddharth Naidu',
                ]}
                multiTextDelay={3000}
                typeSpeed={150}
              /><span>.</span></h1></Fade>
              <Fade bottom duration={800} delay={300}><h2>Frontend Website & Mobile App Developer</h2></Fade>
              <Fade bottom duration={800} delay={400}><p>Over the last six odd years, I've had the pleasure of working with some great companies, working side by side to design and develop Websites.</p></Fade>
              <Fade bottom duration={800} delay={600}><NavLink to="/portfolio/" className="btn"><i class="fa fa-briefcase" aria-hidden="true"></i> View All My Projects</NavLink></Fade>
          </div>
        </div>

        <div className="briefIntro">
          <div className="container">
            <h2>A brief introduction</h2>
            <p>I'm a frontend website developer with a wide range of skills. I specialise in website design & development and have an eye for clean, simple design that grabs the users attention. I have created 90+ websites in my six years of experience in web design & development.</p>
          </div>
        </div>

        <div className="offer">
          <div className="container">

              <h2>What i can offer you</h2>

              <div className="designer">
                <img src={require('../images/web-development.svg')} alt="Siddharth Web Developer" title="Siddharth Web Developer" />
                <h3>Website Development</h3>
                <p>I write both front and back-end code and can implement many features to make your website stand out from the crowd. For business sites, blogs, web apps, and more feel free to get in touch.</p>
                <NavLink to="/portfolio"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
              </div>

              <div className="designer">
                <img src={require('../images/web-design.svg')} alt="Siddharth Web Designer" title="Siddharth Web Designer" />
                <h3>Website Design</h3>
                <p>I also have a passion for web design. If you require a fresh, slick design for a new brand or you need to update an existing site, look me up. I've formed an approach that helps me create unique work; work that you can't get from a template.</p>
                <NavLink to="/portfolio"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
              </div>

              <div className="developer">
                <img src={require('../images/mobile-app.svg')} alt="Siddharth Mobile App Developer" title="Siddharth Mobile App Developer" />
                <h3>Mobile App</h3>
                <p>I figured creating a mobile app with React JS very easy, Using React JS, React Native, and custom HTML and CSS code I developed 3 unique mobile apps. If you require a fresh or update of an existing app, look me up.</p>
                <NavLink to="/portfolio"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
              </div>

          </div>
        </div>

        <div className="offer skills">
          <div className="container">
              <h2>My Skills</h2>
              <div className="mySkills">
                <div className="html">
                  <h3>
                    <CountUp start={10} end={100} duration={3} redraw={true}>
                      {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                          </VisibilitySensor>
                      )}
                    </CountUp>
                  </h3>
                  <p>HTML5, CSS3</p>
                </div>
                <div className="css">
                  <h3>
                    <CountUp start={10} end={90} duration={3} redraw={true}>
                      {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                          </VisibilitySensor>
                      )}
                    </CountUp>
                  </h3>
                  <p>Angular & React JS</p>
                </div>
                <div className="jquery">
                  <h3>
                    <CountUp start={10} end={90} duration={3} redraw={true}>
                      {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                          </VisibilitySensor>
                      )}
                    </CountUp>
                  </h3>
                  <p>WordPress, OpenCart</p>
                </div>
                <div className="bootstrap">
                  <h3>
                    <CountUp start={10} end={90} duration={3} redraw={true}>
                      {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                          </VisibilitySensor>
                      )}
                    </CountUp>
                  </h3>
                  <p>Bootstrap & SASS</p>
                </div>
                <div className="javascript">
                  <h3>
                    <CountUp start={10} end={90} duration={3} redraw={true}>
                      {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                          </VisibilitySensor>
                      )}
                    </CountUp>
                  </h3>
                  <p>Javascript, JQuery</p>
                </div>
                <div className="reactjs">
                  <h3>
                    <CountUp start={10} end={80} duration={3} redraw={true}>
                      {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                          </VisibilitySensor>
                      )}
                    </CountUp>
                  </h3>
                  <p>Photoshop & illustrator</p>
                </div>
              </div>
          </div>
        </div>

        <div className="briefIntro glance">
          <div className="container">
              <h2>A Glance at me</h2>
              <div className="gallery">

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-macbook-air.jpg')}>
                    <img src={require('../images/at-me/siddharth-macbook-air.jpg')} alt="Siddharth Naidu's Macbook (Macbook Air M1)" title="Siddharth Naidu's Macbook (M1 Macbook Air)" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-iphone.jpg')}>
                      <img src={require('../images/at-me/siddharth-iphone.jpg')} alt="Siddharth Naidu's iPhone (iPhone 13 Pro)" title="Siddharth Naidu's iPhone (iPhone 13 Pro)" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-coorg-trip.jpg')}>
                      <img src={require('../images/at-me/siddharth-coorg-trip.jpg')} alt="Coorg Trip" title="Coorg Trip" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-computer.jpg')}>
                      <img src={require('../images/at-me/siddharth-computer.jpg')} alt="Siddharth Naidu's Computer" title="Siddharth Naidu's Computer" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-balcony.jpg')}>
                      <img src={require('../images/at-me/siddharth-balcony.jpg')} alt="Siddharth Naidu's Balcony  (Siddharth's Photography)" title="Siddharth Naidu's Balcony  (Siddharth's Photography)" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-bangalore-palace.jpg')}>
                      <img src={require('../images/at-me/siddharth-bangalore-palace.jpg')} alt="Bangalore Palace  (Siddharth's Photography)" title="Bangalore Palace  (Siddharth's Photography)" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-colleagues.jpg')}>
                      <img src={require('../images/at-me/siddharth-colleagues.jpg')} alt="Siddharth Naidu's Colleagues" title="Siddharth Naidu's Colleagues" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-fridge.jpg')}>
                      <img src={require('../images/at-me/siddharth-fridge.jpg')} alt="Siddharth Naidu's Fridge  (Siddharth's Photography)" title="Siddharth Naidu's Fridge  (Siddharth's Photography)" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-umbrella.jpg')}>
                      <img src={require('../images/at-me/siddharth-umbrella.jpg')} alt="Siddharth Naidu's Umbrella" title="Siddharth Naidu's Umbrella" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-drink.jpg')}>
                      <img src={require('../images/at-me/siddharth-drink.jpg')} alt="Siddharth Naidu's Drink (Siddharth's Photography)" title="Siddharth Naidu's Drink (Siddharth's Photography)" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-dosa.jpg')}>
                      <img src={require('../images/at-me/siddharth-dosa.jpg')} alt="Huge Masala Dosa (Siddharth's Photography)" title="Huge Masala Dosa (Siddharth's Photography)" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-bike.jpg')}>
                      <img src={require('../images/at-me/siddharth-bike.jpg')} alt="Siddharth Naidu's Bike (Siddharth's Photography)" title="Siddharth Naidu's Bike (Siddharth's Photography)" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/mysore-helicopter.jpg')}>
                      <img src={require('../images/at-me/mysore-helicopter.jpg')} alt="Helicopter Ride at Mysore Palace" title="Helicopter Ride at Mysore Palace" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/bangalore-zoo.jpg')}>
                      <img src={require('../images/at-me/bangalore-zoo.jpg')} alt="At Bangalore Zoo (Siddharth's Photography)" title="At Bangalore Zoo (Siddharth's Photography)" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-family.jpg')}>
                      <img src={require('../images/at-me/siddharth-family.jpg')} alt="Siddharth Naidu's Family" title="Siddharth Naidu's Family" />
                  </LightgalleryItem>

                  <LightgalleryItem src={require('../images/at-me/max/siddharth-friends.jpg')}>
                      <img src={require('../images/at-me/siddharth-friends.jpg')} alt="Siddharth Naidu's Friends" title="Siddharth Naidu's Friends" />
                  </LightgalleryItem>

                  <img src={require('../images/banners/about-me.jpg')} alt="Siddharth" title="Siddharth" className="hidden" />
                  <img src={require('../images/cooking/egg-curry.jpg')} alt="Siddharth Naidu's Hobbies" title="Siddharth Naidu's Hobbies" className="hidden" />
                  <img src={require('../images/movies/walking-dead.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" className="hidden" />
                  <img src={require('../images/banners/mysuru.jpg')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" className="hidden" />
                  <img src={require('../images/banners/photos.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="hidden" />
                  <img src={require('../images/banners/portfolio.jpg')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" className="hidden" />              

              </div>
          </div>
        </div>

        <div className="portfolio">
          <div className="container">
              <h2>My Work</h2>
              <div className="project">
                  <figure>
                      <NavLink to="/project/guru-prasadam"><img src={require('../images/portfolio/guru-prasadam.png')} alt="Siddharth Naidu's Portfolio US Cranberries" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>Guru Prasadam</h3>
                      <h4>Built with Opencart, HTML & CSS</h4>
                      <NavLink to="/project/guru-prasadam"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <div className="project">
                  <figure>
                      <NavLink to="/project/us-cranberries"><img src={require('../images/portfolio/us-cranberries.png')} alt="Siddharth Naidu's Portfolio US Cranberries" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>US Cranberries</h3>
                      <h4>Built with WordPress, HTML & CSS</h4>
                      <NavLink to="/project/us-cranberries"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <div className="project">
                  <figure>
                      <NavLink to="/project/itor"><img src={require('../images/portfolio/itor.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>ItoR Automation</h3>
                      <h4>Built with WordPress, HTML & CSS</h4>
                      <NavLink to="/project/itor"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <div className="project">
                  <figure>
                      <NavLink to="/project/kuberan-silks"><img src={require('../images/portfolio/kuberan-silks.png')} alt="Siddharth Naidu's Portfolio Kuberan Silks" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>Kuberan Silks</h3>
                      <h4>Built with Opencart, HTML & CSS</h4>
                      <NavLink to="/project/kuberan-silks"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <div className="project">
                  <figure>
                      <NavLink to="/project/bull-india"><img src={require('../images/portfolio/bull-india.png')} alt="Siddharth Naidu's Portfolio Bull India" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>Bull India</h3>
                      <h4>Built with PHP, HTML & CSS</h4>
                      <NavLink to="/project/bull-india"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <div className="project">
                  <figure>
                      <NavLink to="/project/karlsson-leather"><img src={require('../images/portfolio/karlsson-leather.png')} alt="Siddharth Naidu's Portfolio Karlsson Leather" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>Karlsson Leather</h3>
                      <h4>Built with WordPress, HTML & CSS</h4>
                      <NavLink to="/project/karlsson-leather"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <NavLink to="/portfolio/" className="btn"><i class="fa fa-briefcase" aria-hidden="true"></i> View All My Projects</NavLink>

          </div>
        </div>

        <div className="hobbies">
          <div className="container">
              <h2>My Hobbies</h2>
              <div className="hobbie">
                <figure>
                  <NavLink to="/photos"><img src={require('../images/hobbies/travelling.jpg')} alt="Siddharth Travelling" title="Siddharth Travelling" /></NavLink>
                </figure>
                <h3>Travelling</h3>
                <p>When i'm not creating websites, i love to travel.</p>
                <NavLink to="/photos/" className="btn"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
              </div>
              <div className="hobbie">
                <figure>
                  <NavLink to="/hobbie/photography"><img src={require('../images/hobbies/photography.jpg')} alt="Siddharth Photography" title="Siddharth Photography" /></NavLink>
                </figure>
                <h3>Photography</h3>
                <p>Having fun while capturing memories is my favorite thing.</p>
                <NavLink to="/hobbie/photography/" className="btn"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
              </div>
              <div className="hobbie">
                <figure>
                  <NavLink to="/hobbie/movies"><img src={require('../images/hobbies/movies.jpg')} alt="Siddharth Movies" title="Siddharth Movies" /></NavLink>
                </figure>
                <h3>Netflix Movies</h3>
                <p>Among all, the most common hobby i have Watching Netflix.</p>
                <NavLink to="/hobbie/movies/" className="btn"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
              </div>
              <div className="hobbie">
                <figure>
                  <NavLink to="/hobbie/cooking"><img src={require('../images/hobbies/cooking.jpg')} alt="Siddharth Cooking" title="Siddharth Cooking" /></NavLink>
                </figure>
                <h3>Cooking</h3>
                <p>Perfect for anybody who enjoys eating.</p>
                <NavLink to="/hobbie/cooking/" className="btn"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
              </div>
          </div>
        </div>

      </div>
    )
  }

}

export default Home;
