import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Amaravati extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Amaravati Trip</title>
        </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/photos.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            <h3>Amaravati</h3>
            <h4>When i'm not creating websites, i love to travel.</h4>
        </div>

        <div className="photos">
          <div className="container">

            <div className="mainWrapper">
                <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-26.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-23.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-05.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-02.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-16.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-06.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-08.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-11.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-12.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-13.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            </div>

          </div>
        </div>

      </div>
    )
  }

}

export default Amaravati;
