import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';

class Photos extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Photos | Siddharth's Trips</title>
        </Helmet>

        <div className="banner">
            <img src={require('../images/banners/photos.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            <Fade bottom duration={500} delay={200}><h3>Photos</h3></Fade>
            <Fade bottom duration={500} delay={200}><h4>When i'm not creating websites, i love to travel and explore new culture.</h4></Fade>
        </div>

        <div className="photos">
          <div className="container">
                <div className="trip half">
                    <figure>
                        <img src={require('../images/trips/nandi-hills/siddharth-nandi-hills-trip-banner.jpg')} alt="Siddharth Naidu's Nandi Hills Trip" title="Siddharth Naidu's Nandi Hills Trip" />
                    </figure>
                    <figcaption>
                        <h3>Nandi Hills</h3>
                        <h4>16th Mar 2019 - 19th Mar 2019</h4>
                        <NavLink to="/trip/nandi-hills"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                    </figcaption>
                </div>

              <div className="trip full">
                  <figure>
                      <img src={require('../images/trips/guntur/siddharth-guntur-trip-banner.jpg')} alt="Siddharth Naidu's Guntur Trip" title="Siddharth Naidu's Guntur Trip" />
                  </figure>
                  <figcaption>
                      <h3>Guntur</h3>
                      <h4>3rd Dec 2020 - 20th Mar 2021</h4>
                      <NavLink to="/trip/guntur"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>
              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/amaravati/siddharth-amaravati-trip-banner.jpg')} alt="Siddharth Naidu's Amaravathi Trip" title="Siddharth Naidu's Amaravathi Trip" />
                  </figure>
                  <figcaption>
                      <h3>Amaravati</h3>
                      <h4>19th Feb 2019 - 20th Feb 2021</h4>
                      <NavLink to="/trip/amaravati"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/mysuru/siddharth-mysuru-trip-banner.jpg')} alt="Siddharth Naidu's Mysuru Trip" title="Siddharth Naidu's Mysuru Trip" />
                  </figure>
                  <figcaption>
                      <h3>Mysuru</h3>
                      <h4>18th Sep 2019 - 26th Sep 2019</h4>
                      <NavLink to="/trip/mysuru"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/vijayawada/siddharth-vijayawada-trip-banner.jpg')} alt="Siddharth Naidu's Vijayawada Trip" title="Siddharth Naidu's Vijayawada Trip" />
                  </figure>
                  <figcaption>
                      <h3>Vijayawada</h3>
                      <h4>12th Mar 2018 - 15th Mar 2018</h4>
                      <NavLink to="/trip/vijayawada"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip full tall">
                  <figure>
                      <img src={require('../images/trips/sakleshpur/siddharth-sakleshpur-trip-banner.jpg')} alt="Siddharth Naidu's Sakleshpur Trip" title="Siddharth Naidu's Sakleshpur Trip" />
                  </figure>
                  <figcaption>
                      <h3>Sakleshpur</h3>
                      <h4>4th Sep 2016 - 6th Sep 2016</h4>
                      <NavLink to="/trip/sakleshpur"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip half">
                  <figure>
                      <img src={require('../images/trips/tirupati/siddharth-tirupati-trip-banner.jpg')} alt="Siddharth Naidu's Tirupati Trip" title="Siddharth Naidu's Tirupati Trip" />
                  </figure>
                  <figcaption>
                      <h3>Tirupati</h3>
                      <h4>12th May 2019 - 13th May 2019</h4>
                      <NavLink to="/trip/tirupati"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip half">
                  <figure>
                      <img src={require('../images/trips/bannerghatta-zoo/siddharth-bannerghatta-zoo-trip-banner.jpg')} alt="Siddharth Naidu's Bannerghatta Trip" title="Siddharth Naidu's Bannerghatta Trip" />
                  </figure>
                  <figcaption>
                      <h3>Bannerghatta</h3>
                      <h4>9th July 2017 - 9th July 2017</h4>
                      <NavLink to="/trip/bannerghatta-zoo"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/big-banyan-tree/siddharth-big-banyan-tree-trip-banner.jpg')} alt="Siddharth Naidu's Big Banyan Tree Trip" title="Siddharth Naidu's Big Banyan Tree Trip" />
                  </figure>
                  <figcaption>
                      <h3>Big Banyan Tree</h3>
                      <h4>30th Sep 2018 - 30th Sep 2018</h4>
                      <NavLink to="/trip/big-banyan-tree"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/chikmagalur/siddharth-chikmagalur-trip-banner.jpg')} alt="Siddharth Naidu's Chikmagalur Trip" title="Siddharth Naidu's Chikmagalur Trip" />
                  </figure>
                  <figcaption>
                      <h3>Chikmagalur</h3>
                      <h4>19th Aug 2017 - 20th Aug 2017</h4>
                      <NavLink to="/trip/chikmagalur"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/ooty/siddharth-ooty-trip-banner.jpg')} alt="Siddharth Naidu's Ooty Trip" title="Siddharth Naidu's Ooty Trip" />
                  </figure>
                  <figcaption>
                      <h3>Ooty</h3>
                      <h4>21st Jan 2019 - 22nd Jan 2019</h4>
                      <NavLink to="/trip/ooty"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip full tall">
                  <figure>
                      <img src={require('../images/trips/nandi-hills-2017/siddharth-nandi-hills-trip-banner.jpg')} alt="Siddharth Naidu's Nandi Hills Trip" title="Siddharth Naidu's Nandi Hills Trip" />
                  </figure>
                  <figcaption>
                      <h3>Nandi Hills 2016</h3>
                      <h4>31st Oct 2016 - 31st Oct 2016</h4>
                      <NavLink to="/trip/nandi-hills-2017"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip half">
                  <figure>
                      <img src={require('../images/trips/vidhana-soudha/siddharth-vidhana-soudha-trip-banner.jpg')} alt="Siddharth Naidu's Vidhana Soudha Trip" title="Siddharth Naidu's Vidhana Soudha Trip" />
                  </figure>
                  <figcaption>
                      <h3>Vidhana Soudha</h3>
                      <h4>24th Sep 2018 - 24th Sep 2018</h4>
                      <NavLink to="/trip/vidhana-soudha"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip half">
                  <figure>
                      <img src={require('../images/trips/coorg/siddharth-coorg-trip-banner.jpg')} alt="Siddharth Naidu's Coorg Trip" title="Siddharth Naidu's Coorg Trip" />
                  </figure>
                  <figcaption>
                      <h3>Coorg</h3>
                      <h4>3rd Dec 2016 - 6th Dec 2016</h4>
                      <NavLink to="/trip/coorg"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/wonderla/siddharth-wonderla-trip-banner.jpg')} alt="Siddharth Naidu's Wonderla Trip" title="Siddharth Naidu's Wonderla Trip" />
                  </figure>
                  <figcaption>
                      <h3>Wonderla</h3>
                      <h4>30th Jan 2017 - 30th Jan 2017</h4>
                      <NavLink to="/trip/wonderla"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/palamaner/siddharth-palamaner-trip-banner.jpg')} alt="Siddharth Naidu's Palamaner Trip" title="Siddharth Naidu's Palamaner Trip" />
                  </figure>
                  <figcaption>
                      <h3>Palamaner</h3>
                      <h4>14th May 2017 - 21st May 2017</h4>
                      <NavLink to="/trip/palamaner"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/yelahanka/siddharth-yelahanka-trip-banner.jpg')} alt="Siddharth Naidu's Yelahanka Trip" title="Siddharth Naidu's Yelahanka Trip" />
                  </figure>
                  <figcaption>
                      <h3>Yelahanka</h3>
                      <h4>31st Dec 2018 - 31st Dec 2018</h4>
                      <NavLink to="/trip/yelahanka"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/guntur-2016/siddharth-guntur-trip-banner.jpg')} alt="Siddharth Naidu's Guntur Trip" title="Siddharth Naidu's Guntur Trip" />
                  </figure>
                  <figcaption>
                      <h3>Guntur</h3>
                      <h4>21st Sep 2016 - 26th Nov 2016</h4>
                      <NavLink to="/trip/guntur-2016"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/salem/siddharth-salem-trip-banner.jpg')} alt="Siddharth Naidu's Salem Trip" title="Siddharth Naidu's Salem Trip" />
                  </figure>
                  <figcaption>
                      <h3>Salem</h3>
                      <h4>10th July 2016 & 22nd June 2019</h4>
                      <NavLink to="/trip/salem"><i class="fa fa-angle-right" aria-hidden="true"></i> View all photos...</NavLink>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/tipu-sultan-palace/siddharth-tipu-sultan-palace-trip-banner.jpg')} alt="Siddharth Naidu's Tipu Sultan Palace Trip" title="Siddharth Naidu's Tipu Sultan Palace Trip" />
                  </figure>
                  <figcaption>
                      <h3>Tipu Sultan Palace</h3>
                      <h4>17th Mar 2017 - 18th Mar 2017</h4>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/ananthapur-forest/siddharth-ananthapur-forest-trip-banner.jpg')} alt="Siddharth Naidu's Ananthapur Forest Trip" title="Siddharth Naidu's Ananthapur Forest Trip" />
                  </figure>
                  <figcaption>
                      <h3>Ananthapur Forest</h3>
                      <h4>23rd Apr 2015 - 24th Apr 2015</h4>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/vizag/siddharth-vizag-trip-banner.jpg')} alt="Siddharth Naidu's Vizag Trip" title="Siddharth Naidu's Vizag Trip" />
                  </figure>
                  <figcaption>
                      <h3>Vizag</h3>
                      <h4>15th Aug 2014 - 20th Aug 2014</h4>
                  </figcaption>
              </div>

              <div className="trip">
                  <figure>
                      <img src={require('../images/trips/simhachalam/siddharth-simhachalam-trip-banner.jpg')} alt="Siddharth Naidu's Simhachalam Trip" title="Siddharth Naidu's Simhachalam Trip" />
                  </figure>
                  <figcaption>
                      <h3>Simhachalam</h3>
                      <h4>10th Aug 2014 - 14th Aug 2014</h4>
                  </figcaption>
              </div>
          </div>
        </div>

      </div>
    )
  }

}

export default Photos;
