import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { Helmet } from 'react-helmet';

class Siddharthnaidu extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

      <Helmet>
          <title>Siddharthnaidu.com Portfolio Website | Siddharth Naidu's Portfolio</title>
      </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/portfolio.jpg')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
            <h3>Siddharth Naidu</h3>
            <h4>Portfolio Website</h4>
        </div>

        <div className="project-info">
          <div className="container">
              <figure>
                <img src={require('../../images/portfolio/mockup/siddharth-naidu.png')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
              </figure>
              <div className="project-details">
                  <h2>Siddharth Naidu</h2>
                  <h3>I needed a portfolio website in order to showcase my abilities and gain work as a Frontend website and mobile app developer.</h3>
                  <p>Technologies used React JS, Node JS, HTML and CSS. With custom own html and css code i made this website load faster for better user experience.</p>
              </div>
              <div className="project-side">
                  <h4>Duration</h4>
                  <p>45 days and counting</p>
                  <h4>Project Type</h4>
                  <p>Portfolio Website</p>
                  <h4>Project Homepage</h4>
                  <p><a href="https://www.siddharthnaidu.com">www.siddharthnaidu.com</a></p>
              </div>
          </div>
        </div>

        <div className="project-navigation">
          <div className="container">
              <NavLink to="/project/vanismart">
                <div className="prev">
                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    <p>Previous</p>
                    <h4>Vanismart</h4>
                </div>
              </NavLink>
              <NavLink to="/project/blog">
                <div className="next">
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    <p>Next</p>
                    <h4>Blog</h4>
                </div>
              </NavLink>
          </div>
        </div>

      </div>
    )
  }

}

export default Siddharthnaidu;
