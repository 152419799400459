import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


class Guntur extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Guntur Trip</title>
        </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/photos.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            <h3>Guntur</h3>
            <h4>When i'm not creating websites, i love to travel.</h4>
        </div>

        <div className="photos">
          <div className="container">

            <div className="mainWrapper">
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-01.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-02.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-03.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-04.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-05.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-6.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-06.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-07.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-08.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-09.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-10.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-11.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-12.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-13.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-15.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-16.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-17.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-18.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-19.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-20.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-21.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-22.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-24.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-25.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-26.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-27.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-28.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-29.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-30.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-31.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-32.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur/siddharth-guntur-trip-33.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            </div>

          </div>
        </div>

      </div>
    )
  }

}

export default Guntur;
