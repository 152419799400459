import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';

class Movies extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Movies | Siddharth's Hobbies</title>
        </Helmet>

        <div className="banner">
            <img src={require('../images/movies/walking-dead.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
            <Fade bottom duration={500} delay={200}><h3>Movies</h3></Fade>
            <Fade bottom duration={500} delay={200}><h4>The most common hobby i have Watching Movies.</h4></Fade>
        </div>

        <div className="photos">
          <div className="container">

              <div className="mainWrapper">
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/the-100.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>THE 100</h3>
                        <h4>This one is my all time favorite Netflix TV show.</h4>
                        <p>A century after Earth was devastated by a nuclear apocalypse, 100 space station residents are sent to the planet to determine whether it's habitable.</p>
                      </figcaption>
                  </div>
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/vampire-diaries.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>Vampire Diaries</h3>
                        <h4>Amazon Prime TV Show.</h4>
                        <p>On her first day at high school, Elena meets Stefan and immediately feels a connection with him. However, what she doesn't know is that Stefan and his brother, Damon, are in fact vampires.</p>
                      </figcaption>
                  </div>
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/another-life.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>Another Life</h3>
                        <h4>Netflix TV Show.</h4>
                        <p>Astronaut Niko Breckinridge is focused on searching the world for alien intelligence. She leads a crew that is on a mission to explore the genesis of an alien artifact that has been found on Earth.</p>
                      </figcaption>
                  </div>
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/hold-the-dark.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>Hold the Dark</h3>
                        <h4>Netflix Original Movie.</h4>
                        <p>Summoned to a remote Alaskan village to search for the wolves that killed three children, a wolf expert soon finds himself unravelling a harrowing mystery.</p>
                      </figcaption>
                  </div>
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/walking-dead.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>The Walking Dead</h3>
                        <h4>Netflix TV show.</h4>
                        <p>Rick searches for his family in a world terrorized by the walking dead. Morgan and Lori and Shane deal with the return of someone they thought was dead.</p>
                      </figcaption>
                  </div>
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/the-i-land.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>The i-land</h3>
                        <h4>Netflix TV show.</h4>
                        <p>When a colonel uncovers controversial intel about the government, he makes a shocking discovery and must decide whether to reveal it or risk his life.</p>
                      </figcaption>
                  </div>
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/zoo.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>ZOO</h3>
                        <h4>Netflix TV show.</h4>
                        <p>A young scientist searches to find out what's causing a rash of violent animal attacks. ... Jackson and the others are arrive at Zambia to find the leopard to test Mitch's theory of the animal mutation.</p>
                      </figcaption>
                  </div>
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/jack-giant-slayer.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>Jack the Giant Slayer</h3>
                        <h4>Hollywood Movie.</h4>
                        <p>Jack, a young farmhand, must save Princess Isabelle from the giants after one of the magic beans that a monk gives him in exchange of his horse sprouts a giant beanstalk into the realm of the giants.</p>
                      </figcaption>
                  </div>
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/pacific-rim.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>Pacific Rim</h3>
                        <h4>Hollywood Movie.</h4>
                        <p>The government assumes the Jaegers, robotic war machines battling the Kaijus, to be ineffective. However, Stacker Pentecost's team believes that only the Jaegers can save the world from destruction.</p>
                      </figcaption>
                  </div>
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/kong.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>Kong Skull island</h3>
                        <h4>Hollywood Movie.</h4>
                        <p>A crew reaches Skull Island in order to map it, but its members are attacked by a humongous ape. A few of them regroup and find out more about the ape, the island's natives and underground monsters.</p>
                      </figcaption>
                  </div>
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/avengers.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>Avengers</h3>
                        <h4>Marvel Movies.</h4>
                        <p>The Avengers must stop Thanos, an intergalactic warlord, from getting his hands on all the infinity stones. However, Thanos is prepared to go to any lengths to carry out his insane plan.</p>
                      </figcaption>
                  </div>
                  <div className="tvshow">
                      <figure>
                        <img src={require('../images/movies/ben-10.jpg')} alt="Siddharth Naidu's favorite movies" title="Siddharth Naidu's favorite movies" />
                      </figure>
                      <figcaption>
                        <h3>Ben 10</h3>
                        <h4>Hollywood Cartoon TV show.</h4>
                        <p>Ten-year-old Ben Tennyson discovers a mysterious device named the Omnitrix on a family vacation. The device allows him to transform into 10 different alien forms replete with unique superpowers.</p>
                      </figcaption>
                  </div>
              </div>

            </div>

        </div>

      </div>
    )
  }

}

export default Movies;
