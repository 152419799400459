import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class Aromamilk extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

      <Helmet>
          <title>Aroma Milk | Siddharth Naidu's Portfolio</title>
      </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/portfolio.jpg')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
            <h3>Aroma Milk</h3>
            <h4>Online Marketing Website</h4>
        </div>

        <div className="project-info">
          <div className="container">
              <figure>
                <img src={require('../../images/portfolio/mockup/aroma-milk.png')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
              </figure>
              <div className="project-details">
                  <h2>Aroma Milk</h2>
                  <h3>It's a mobile responsive website built with custom code and launched in 12 days.</h3>
                  <p>Technologies used HTML and CSS, Bootstrap, Jquery, little bit PHP. With custom html and css code i made this website load faster for better user experience.</p>
              </div>
              <div className="project-side">
                  <h4>Duration</h4>
                  <p>12 days</p>
                  <h4>Project Type</h4>
                  <p>Online Marketing Website</p>
                  <h4>Website URL</h4>
                  <p><a href="http://www.aromamilk.com/" target="_blank">www.aromamilk.com</a></p>
              </div>
          </div>
        </div>

        <div className="project-navigation">
          <div className="container">
              <NavLink to="/project/essae-dig">
                <div className="prev">
                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    <p>Previous</p>
                    <h4>Essae Dig</h4>
                </div>
              </NavLink>
              <NavLink to="/project/prasad-photography">
                <div className="next">
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    <p>Next</p>
                    <h4>Prasad Photography</h4>
                </div>
              </NavLink>
          </div>
        </div>

      </div>
    )
  }

}

export default Aromamilk;
