import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Bigbanyantree extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Big Banyan Tree Trip</title>
        </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/photos.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            <h3>Big Banyan Tree</h3>
            <h4>When i'm not creating websites, i love to travel.</h4>
        </div>

        <div className="photos">
          <div className="container">

            <div className="mainWrapper">
                <img src={require('../../images/trips/big-banyan-tree/siddharth-big-banyan-tree-trip-01.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/big-banyan-tree/siddharth-big-banyan-tree-trip-04.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/big-banyan-tree/siddharth-big-banyan-tree-trip-02.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/big-banyan-tree/siddharth-big-banyan-tree-trip-03.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/big-banyan-tree/siddharth-big-banyan-tree-trip-06.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/big-banyan-tree/siddharth-big-banyan-tree-trip-07.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                <img src={require('../../images/trips/big-banyan-tree/siddharth-big-banyan-tree-trip-05.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
            </div>

          </div>
        </div>

      </div>
    )
  }

}

export default Bigbanyantree;
