import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default function Portfolio(props) {
    const [scroll, setScroll] = useState(false)
    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 250)
      })
        window.scrollTo(0, 0)
    }, [])
    return (
      <div className="app">

          <Helmet>
              <title>Portfolio | Siddharth Frontend Website Developer with 5 years of Experience</title>
          </Helmet>

          <div className="banner">
              <img src={require('../images/banners/portfolio.jpg')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
              <Fade bottom duration={500} delay={200}><h3>Portfolio</h3></Fade>
              <Fade bottom duration={500} delay={200}><h4>i have created 80+ websites in my five years of experience in web development.</h4></Fade>
          </div>

          <div className="portfolio portfolioMain">
              <div className="container">

                  <Tabs className={scroll ? "tabs-fixed" : "tabs-sticky"}>

                      <TabList>
                          <a href="#">
                            <Tab><i class="fa fa-bookmark" aria-hidden="true"></i> Featured Projects</Tab>
                            <Tab><i class="fa fa-shopping-cart" aria-hidden="true"></i> E-Commerce Websites</Tab>
                            <Tab><i class="fa fa-wordpress" aria-hidden="true"></i> WordPress Projects</Tab>
                            <Tab><i class="fa fa-code" aria-hidden="true"></i> React & Node JS Projects</Tab>
                            <Tab><i class="fa fa-tasks" aria-hidden="true"></i> Full Stack Projects</Tab>
                            <Tab><i class="fa fa-desktop" aria-hidden="true"></i> Static Websites</Tab>
                            <Tab><i class="fa fa-toggle-on" aria-hidden="true"></i> Mobile Apps</Tab>
                            <Tab><i class="fa fa-paint-brush" aria-hidden="true"></i> Website Designs</Tab>
                            <Tab><i class="fa fa-picture-o" aria-hidden="true"></i> Graphic Designs</Tab>
                            <Tab><i class="fa fa-youtube-play" aria-hidden="true"></i> Video Editing</Tab>
                            <Tab><i class="fa fa-clock-o" aria-hidden="true"></i> Recent Projects</Tab>
                            <Tab><i class="fa fa-check-circle" aria-hidden="true"></i> All Projects</Tab>
                          </a>
                      </TabList>

                      <TabPanel>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/us-cranberries"><img src={require('../images/portfolio/us-cranberries.png')} alt="Siddharth Naidu's Portfolio US Cranberries" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>US Cranberries</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/us-cranberries"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/guru-prasadam"><img src={require('../images/portfolio/guru-prasadam.png')} alt="Siddharth Naidu's Portfolio Guru Prasadam" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Guru Prasadam</h3>
                                  <h4>Built with Opencart, HTML & CSS</h4>
                                  <NavLink to="/project/guru-prasadam"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/bull-india"><img src={require('../images/portfolio/bull-india.png')} alt="Siddharth Naidu's Portfolio Bull India" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Bull India</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/bull-india"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/essae"><img src={require('../images/portfolio/essae.png')} alt="Siddharth Naidu's Portfolio Essae" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Essae</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/essae"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/karlsson-leather"><img src={require('../images/portfolio/karlsson-leather.png')} alt="Siddharth Naidu's Portfolio Karlsson Leather" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Karlsson Leather</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/karlsson-leather"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/kuberan-silks"><img src={require('../images/portfolio/kuberan-silks.png')} alt="Siddharth Naidu's Portfolio Kuberan Silks" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Kuberan Silks</h3>
                                  <h4>Built with Opencart, HTML & CSS</h4>
                                  <NavLink to="/project/kuberan-silks"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/vanismart"><img src={require('../images/portfolio/vanismart.png')} alt="Siddharth Naidu's Portfolio Vanismart" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Vanismart</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/vanismart"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/prima-baby"><img src={require('../images/portfolio/prima-baby.png')} alt="Siddharth Naidu's Portfolio Prima Baby" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Prima Baby</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/prima-baby"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/dearadhpro"><img src={require('../images/portfolio/dearadhpro.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>DearadhPro</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/dearadhpro"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>
                          <div className="project">
                              <figure>
                                  <NavLink to="/project/itor"><img src={require('../images/portfolio/itor.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>ItoR Automation</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/itor"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/lamppost-media"><img src={require('../images/portfolio/lamppost-media.png')} alt="Siddharth Naidu's Portfolio Lamppost Media" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Lamppost Media</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/lamppost-media"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/lexington"><img src={require('../images/portfolio/lexington.png')} alt="Siddharth Naidu's Portfolio Lexington" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Lexington</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/lexington"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/elrich-life"><img src={require('../images/portfolio/elrich-life.png')} alt="Siddharth Naidu's Portfolio Elrich Life" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Elrich Life</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/elrich-life"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/abhee-nandana"><img src={require('../images/portfolio/abhee-nandana.png')} alt="Siddharth Naidu's Portfolio Abhee Nandana" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Abhee Nandana</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/abhee-nandana"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/online-king"><img src={require('../images/portfolio/online-king.png')} alt="Siddharth Naidu's Portfolio Online King" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Online King</h3>
                                  <h4>Built with Opencart, HTML & CSS</h4>
                                  <NavLink to="/project/online-king"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/rajashri-foods"><img src={require('../images/portfolio/rajashri-foods.png')} alt="Siddharth Naidu's Portfolio Rajashri Foods" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Rajashri Foods</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/rajashri-foods"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/essae-dig"><img src={require('../images/portfolio/essae-dig.png')} alt="Siddharth Naidu's Portfolio Essae Dig" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Essae Dig</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/essae-dig"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/aroma-milk"><img src={require('../images/portfolio/aroma-milk.png')} alt="Siddharth Naidu's Portfolio Aroma Milk" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Aroma Milk</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/aroma-milk"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>
                      </TabPanel>

                      <TabPanel>
                          <div className="project">
                              <figure>
                                  <NavLink to="/project/guru-prasadam"><img src={require('../images/portfolio/guru-prasadam.png')} alt="Siddharth Naidu's Portfolio Guru Prasadam" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Guru Prasadam</h3>
                                  <h4>Built with Opencart, HTML & CSS</h4>
                                  <NavLink to="/project/guru-prasadam"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                            <figure>
                                <NavLink to="/project/kuberan-silks"><img src={require('../images/portfolio/kuberan-silks.png')} alt="Siddharth Naidu's Portfolio Kuberan Silks" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Kuberan Silks</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/kuberan-silks"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/equeris"><img src={require('../images/portfolio/equeris.png')} alt="Siddharth Naidu's Portfolio EQueris" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>EQueris</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/equeris"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gagana-books"><img src={require('../images/portfolio/gagana-books.png')} alt="Siddharth Naidu's Portfolio EQueris" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gagana Books</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gagana-books"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/vanismart"><img src={require('../images/portfolio/vanismart.png')} alt="Siddharth Naidu's Portfolio Vanismart" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Vanismart</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/vanismart"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gearnride"><img src={require('../images/portfolio/gearnride.png')} alt="Siddharth Naidu's Portfolio Gear N Ride" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gear N Ride</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gearnride"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/online-king"><img src={require('../images/portfolio/online-king.png')} alt="Siddharth Naidu's Portfolio Online King" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Online King</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/online-king"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/aroma-milk"><img src={require('../images/portfolio/aroma-milk.png')} alt="Siddharth Naidu's Portfolio Aroma Milk" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Aroma Milk</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/aroma-milk"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/ms-pink-panther"><img src={require('../images/portfolio/ms-pink-panther.png')} alt="Siddharth Naidu's Portfolio MS Pink Panther" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>MS Pink Panther</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/ms-pink-panther"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/nehas-bags"><img src={require('../images/portfolio/nehas-bags.png')} alt="Siddharth Naidu's Portfolio Nehas Bags" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Nehas Bags</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/nehas-bags"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/pavechas"><img src={require('../images/portfolio/pavechas.png')} alt="Siddharth Naidu's Portfolio Pavechas" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Pavechas</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/pavechas"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/iyengars-bakery"><img src={require('../images/portfolio/iyengars-bakery.png')} alt="Siddharth Naidu's Portfolio Iyengars Bakery" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Iyengars Bakery</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/iyengars-bakery"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/mkc-food-products"><img src={require('../images/portfolio/mkc-food-products.png')} alt="Siddharth Naidu's Portfolio MKC Food Products" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>MKC Food Products</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/mkc-food-products"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/pjt-foods"><img src={require('../images/portfolio/pjt-foods.png')} alt="Siddharth Naidu's Portfolio PJT Foods" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>PJT Foods</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/pjt-foods"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/snm-masala"><img src={require('../images/portfolio/snm-masala.png')} alt="Siddharth Naidu's Portfolio SNM Masala" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>SNM Masala</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/snm-masala"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/venyaa-chemicals"><img src={require('../images/portfolio/venyaa-chemicals.png')} alt="Siddharth Naidu's Portfolio Venyaa Chemicals" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Venyaa Chemicals</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/venyaa-chemicals"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                      </TabPanel>

                      <TabPanel>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/armadaa"><img src={require('../images/portfolio/armadaa.png')} alt="Siddharth Naidu's Portfolio Armadaa" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Armadaa</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/armadaa"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/gagana-books"><img src={require('../images/portfolio/gagana-books.png')} alt="Siddharth Naidu's Portfolio EQueris" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Gagana Books</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/gagana-books"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/vanismart"><img src={require('../images/portfolio/vanismart.png')} alt="Siddharth Naidu's Portfolio Vanismart" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Vanismart</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/vanismart"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/dearadhpro"><img src={require('../images/portfolio/dearadhpro.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>DearadhPro</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/dearadhpro"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/us-cranberries"><img src={require('../images/portfolio/us-cranberries.png')} alt="Siddharth Naidu's Portfolio US Cranberries" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>US Cranberries</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/us-cranberries"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/itor"><img src={require('../images/portfolio/itor.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>ItoR Automation</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/itor"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/karlsson-leather"><img src={require('../images/portfolio/karlsson-leather.png')} alt="Siddharth Naidu's Portfolio Karlsson Leather" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Karlsson Leather</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/karlsson-leather"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                            <figure>
                                <NavLink to="/project/gensuite"><img src={require('../images/portfolio/gensuite.png')} alt="Siddharth Naidu's Portfolio Gensuite" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gensuite</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gensuite"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gensuite-india"><img src={require('../images/portfolio/gensuite-india.png')} alt="Siddharth Naidu's Portfolio Gensuite India" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gensuite India</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gensuite-india"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gensuite-conference"><img src={require('../images/portfolio/gensuite-conference.png')} alt="Siddharth Naidu's Portfolio Gensuite Conference" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gensuite Conference</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gensuite-conference"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gensuite-csr"><img src={require('../images/portfolio/gensuite-csr.png')} alt="Siddharth Naidu's Portfolio Gensuite CSR" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gensuite CSR</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gensuite-csr"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gearnride"><img src={require('../images/portfolio/gearnride.png')} alt="Siddharth Naidu's Portfolio Gear N Ride" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gear N Ride</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gearnride"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/dr-venus"><img src={require('../images/portfolio/dr-venus.png')} alt="Siddharth Naidu's Portfolio Dr.Venus" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Dr.Venus</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/dr-venus"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/uni-gauge"><img src={require('../images/portfolio/uni-gauge.png')} alt="Siddharth Naidu's Portfolio Unigauge" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Unigauge</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/uni-gauge"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/digital-exchange"><img src={require('../images/portfolio/digital-exchange.png')} alt="Siddharth Naidu's Portfolio Digital Exchange" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Digital Exchange</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/digital-exchange"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/digiqom"><img src={require('../images/portfolio/digiqom.png')} alt="Siddharth Naidu's Portfolio Digiqom" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Digiqom</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/digiqom"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/indus"><img src={require('../images/portfolio/indus.png')} alt="Siddharth Naidu's Portfolio Indus" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Indus</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/indus"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/namasthe-andhra"><img src={require('../images/portfolio/namasthe-andhra.png')} alt="Siddharth Naidu's Portfolio Namasthe Andhra" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Namasthe Andhra</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/namasthe-andhra"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/dzinelab"><img src={require('../images/portfolio/dzinelab.png')} alt="Siddharth Naidu's Portfolio Dzinelab" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Dzinelab</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/dzinelab"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/venyaa-chemicals"><img src={require('../images/portfolio/venyaa-chemicals.png')} alt="Siddharth Naidu's Portfolio Venyaa Chemicals" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Venyaa Chemicals</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/venyaa-chemicals"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/cbc-fashions"><img src={require('../images/portfolio/cbc-fashions.png')} alt="Siddharth Naidu's Portfolio CBC Fashions" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>CBC Fashions</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/cbc-fashions"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/sam-pumps"><img src={require('../images/portfolio/sam-pumps.png')} alt="Siddharth Naidu's Portfolio Sam Pumps" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Sam Pumps</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/sam-pumps"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/thrayi"><img src={require('../images/portfolio/thrayi.png')} alt="Siddharth Naidu's Portfolio Thrayi" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Thrayi</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/thrayi"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/tinakamal"><img src={require('../images/portfolio/tinakamal.png')} alt="Siddharth Naidu's Portfolio Tinakamal" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Tina Kamal</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/tinakamal"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/archies-projects"><img src={require('../images/portfolio/archies-projects.png')} alt="Siddharth Naidu's Portfolio Archies Projects" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Archies Projects</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/archies-projects"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/yaseen-overseas"><img src={require('../images/portfolio/yaseen-overseas.png')} alt="Siddharth Naidu's Portfolio Yaseen Overseas" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Yaseen Overseas</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/yaseen-overseas"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/bangalore-rmc"><img src={require('../images/portfolio/bangalore-rmc.png')} alt="Siddharth Naidu's Portfolio Bangalore RMC" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Bangalore RMC</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/bangalore-rmc"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="#"><img src={require('../images/portfolio/gt-infra-trade.png')} alt="Siddharth Naidu's Portfolio GT Infra Trade" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>GT Infra Trade</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="#"><img src={require('../images/portfolio/hotel-harsha.png')} alt="Siddharth Naidu's Portfolio Hotel Harsha" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Hotel Harsha</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="#"><img src={require('../images/portfolio/deccan-equipments.png')} alt="Siddharth Naidu's Portfolio Deccan Equipments" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Deccan Equipments</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="#"><img src={require('../images/portfolio/citizen-builders.png')} alt="Siddharth Naidu's Portfolio Citizen Builders" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Citizen Builders</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                            </figcaption>
                        </div>

                      </TabPanel>

                      <TabPanel>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/siddharth-naidu"><img src={require('../images/portfolio/siddharth-naidu.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Siddharth Naidu</h3>
                                  <h4>Built with React JS, HTML & CSS</h4>
                                  <NavLink to="/project/siddharth-naidu"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                      </TabPanel>

                      <TabPanel>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/blog"><img src={require('../images/portfolio/blog.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Blog</h3>
                                  <h4>Built with MEAN Stack, HTML & CSS</h4>
                                  <NavLink to="/project/blog"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                      </TabPanel>

                      <TabPanel>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/bull-india"><img src={require('../images/portfolio/bull-india.png')} alt="Siddharth Naidu's Portfolio Bull India" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Bull India</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/bull-india"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/essae"><img src={require('../images/portfolio/essae.png')} alt="Siddharth Naidu's Portfolio Essae" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Essae</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/essae"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/prima-baby"><img src={require('../images/portfolio/prima-baby.png')} alt="Siddharth Naidu's Portfolio Prima Baby" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Prima Baby</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/prima-baby"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/ibca-bangalore"><img src={require('../images/portfolio/ibca-bangalore.png')} alt="Siddharth Naidu's Portfolio IBCA" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>IBCA</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/ibca-bangalore"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/abhee-nandana"><img src={require('../images/portfolio/abhee-nandana.png')} alt="Siddharth Naidu's Portfolio Abhee Nandana" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Abhee Nandana</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/abhee-nandana"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/dequalita"><img src={require('../images/portfolio/dequalita.png')} alt="Siddharth Naidu's Portfolio DeQualita" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>DeQualita</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/dequalita"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/rajashri-foods"><img src={require('../images/portfolio/rajashri-foods.png')} alt="Siddharth Naidu's Portfolio Rajashri Foods" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Rajashri Foods</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/rajashri-foods"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/essae-dig"><img src={require('../images/portfolio/essae-dig.png')} alt="Siddharth Naidu's Portfolio Essae Dig" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Essae Dig</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/essae-dig"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/aroma-milk"><img src={require('../images/portfolio/aroma-milk.png')} alt="Siddharth Naidu's Portfolio Aroma Milk" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Aroma Milk</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/aroma-milk"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/prasad-photography"><img src={require('../images/portfolio/prasad-photography.png')} alt="Siddharth Naidu's Portfolio Prasad Photography" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Prasad Photography</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/prasad-photography"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/tekcel"><img src={require('../images/portfolio/tekcel.png')} alt="Siddharth Naidu's Portfolio Tekcel" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Tekcel</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/tekcel"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/mkc-food-products"><img src={require('../images/portfolio/mkc-food-products.png')} alt="Siddharth Naidu's Portfolio MKC Food Products" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>MKC Food Products</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/mkc-food-products"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/kirthika-international"><img src={require('../images/portfolio/kirthika-international.png')} alt="Siddharth Naidu's Portfolio Kirthika International" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Kirthika International</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/kirthika-international"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/renaatus"><img src={require('../images/portfolio/renaatus.png')} alt="Siddharth Naidu's Portfolio Renaatus Projects" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Renaatus Projects</h3>
                                  <h4>Built with PHP, HTML & CSS</h4>
                                  <NavLink to="/project/renaatus"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                            <figure>
                                <NavLink to="/project/saideep-exports"><img src={require('../images/portfolio/saideep-exports.png')} alt="Siddharth Naidu's Portfolio Saideep Exports" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Saideep Exports</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/saideep-exports"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/incite-cam-centre"><img src={require('../images/portfolio/incite-cam-centre.png')} alt="Siddharth Naidu's Portfolio Incite Cam Centre" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Incite Cam Centre</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/incite-cam-centre"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/kargil-equipments"><img src={require('../images/portfolio/kargil-equipments.png')} alt="Siddharth Naidu's Portfolio Kargil Equipments" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Kargil Equipments</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/kargil-equipments"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/sandisol"><img src={require('../images/portfolio/sandisol.png')} alt="Siddharth Naidu's Portfolio Sandisol" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Sandisol</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/sandisol"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/secuserve"><img src={require('../images/portfolio/secuserve.png')} alt="Siddharth Naidu's Portfolio Secuserve" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Secuserve</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/secuserve"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/the-talk"><img src={require('../images/portfolio/the-talk.png')} alt="Siddharth Naidu's Portfolio The Talk" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>The Talk</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/the-talk"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/uv-gullas"><img src={require('../images/portfolio/uv-gullas.png')} alt="Siddharth Naidu's Portfolio UN Gullas" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>UV Gullas</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/uv-gullas"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/appac-media"><img src={require('../images/portfolio/appac-media.png')} alt="Siddharth Naidu's Portfolio Appac Media" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Appac Media</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/appac-media"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/glittek-granites"><img src={require('../images/portfolio/glittek-granites.png')} alt="Siddharth Naidu's Portfolio Glittek Granites" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Glittek Granites</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/glittek-granites"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/glittek-granites"><img src={require('../images/portfolio/msrmh.png')} alt="Siddharth Naidu's Portfolio Glittek Granites" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>MSRMH</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/msrmh/"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/igauge"><img src={require('../images/portfolio/igauge.png')} alt="Siddharth Naidu's Portfolio iGauge" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>iGauge</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/igauge"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/floret-media"><img src={require('../images/portfolio/floret-media.png')} alt="Siddharth Naidu's Portfolio Floret Media" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Floret Media</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/floret-media"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gmg-advisors"><img src={require('../images/portfolio/gmg-advisors.png')} alt="Siddharth Naidu's Portfolio GMG Advisors" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>GMG Advisors</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/gmg-advisors"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/alankar-logistics"><img src={require('../images/portfolio/alankar-logistics.png')} alt="Siddharth Naidu's Portfolio Alankar Logistics" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Alankar Logistics</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/alankar-logistics"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/utcom"><img src={require('../images/portfolio/utcom.png')} alt="Siddharth Naidu's Portfolio Utcom" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Utcom</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/utcom"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/comet-metallic"><img src={require('../images/portfolio/comet-metallic.png')} alt="Siddharth Naidu's Portfolio Comet Metallic" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Comet Metallic</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/comet-metallic"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/1mg-lido-mall"><img src={require('../images/portfolio/1mg-lido-mall.png')} alt="Siddharth Naidu's Portfolio 1MG Lido Mall" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>1MG Lido Mall</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/1mg-lido-mall"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/geewin-exim"><img src={require('../images/portfolio/geewin-exim.png')} alt="Siddharth Naidu's Portfolio Geewin Exim" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Geewin Exim</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/geewin-exim"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/mta-live-events"><img src={require('../images/portfolio/mta-live-events.png')} alt="Siddharth Naidu's Portfolio MTA Live Events" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>MTA Live Events</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/mta-live-events"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/pjt-foods"><img src={require('../images/portfolio/pjt-foods.png')} alt="Siddharth Naidu's Portfolio PJT Foods" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>PJT Foods</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/pjt-foods"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/propel"><img src={require('../images/portfolio/propel.png')} alt="Siddharth Naidu's Portfolio Propel" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Propel</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/propel"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/dutyx"><img src={require('../images/portfolio/dutyx.png')} alt="Siddharth Naidu's Portfolio DutyX" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>DutyX</h3>
                                <h4>Built with HTML & CSS</h4>
                                <NavLink to="/project/dutyx"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/ps-consultants"><img src={require('../images/portfolio/ps-consultants.png')} alt="Siddharth Naidu's Portfolio PS Consultants" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>PS Consultants</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/ps-consultants"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/snm-masala"><img src={require('../images/portfolio/snm-masala.png')} alt="Siddharth Naidu's Portfolio SNM Masala" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>SNM Masala</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/snm-masala"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/mr-intelligent"><img src={require('../images/portfolio/mr-intelligent.png')} alt="Siddharth Naidu's Portfolio Mr.Intelligent" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Mr.Intelligent</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/mr-intelligent"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/rentozy"><img src={require('../images/portfolio/rentozy.png')} alt="Siddharth Naidu's Portfolio Rentozy" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Rentozy</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/rentozy"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/cbc-fashions"><img src={require('../images/portfolio/cbc-fashions.png')} alt="Siddharth Naidu's Portfolio CBC Fashions" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>CBC Fashions</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/cbc-fashions"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/sam-pumps"><img src={require('../images/portfolio/sam-pumps.png')} alt="Siddharth Naidu's Portfolio Sam Pumps" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Sam Pumps</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/sam-pumps"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                      </TabPanel>

                      <TabPanel>

                          <div className="project">
                              <figure>
                                  <img src={require('../images/mobile-apps/login-form.png')} alt="Siddharth Naidu's Portfolio" />
                              </figure>
                              <figcaption>
                                  <h3>Login Form</h3>
                                  <h4>Built with React Native & EXPO</h4>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <img src={require('../images/mobile-apps/online-shopping.png')} alt="Siddharth Naidu's Portfolio" />
                              </figure>
                              <figcaption>
                                  <h3>Online Shopping</h3>
                                  <h4>Built with React Native & EXPO</h4>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <img src={require('../images/mobile-apps/7wonders.png')} alt="Siddharth Naidu's Portfolio" />
                              </figure>
                              <figcaption>
                                  <h3>7 Wonders</h3>
                                  <h4>Built with React Native & EXPO</h4>
                              </figcaption>
                          </div>

                      </TabPanel>

                      <TabPanel>

                            <div className="project">
                                <img src={require('../images/design/dequalita.png')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
                            </div>

                            <div className="project">
                                <img src={require('../images/design/online-king.png')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
                            </div>

                            <div className="project">
                                <img src={require('../images/design/igauge.png')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
                                <img src={require('../images/design/tinakamal.png')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
                            </div>

                      </TabPanel>

                      <TabPanel>

                            <div className="project">
                                <img src={require('../images/design/house-warming-card.jpg')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
                                <img src={require('../images/design/lachico-full.jpeg')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
                            </div>

                            <div className="project">
                                <img src={require('../images/design/lachico.jpeg')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
                            </div>

                            <div className="project">
                                <img src={require('../images/design/yaseen-overseas.jpeg')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
                                <img src={require('../images/design/digiknots.jpeg')} alt="Siddharth Naidu's Portfolio" title="Siddharth Naidu's Portfolio" />
                            </div>

                      </TabPanel>

                      <TabPanel>

                            <div className="project">
                                  <iframe width="560" height="315" src="https://www.youtube.com/embed/huWxPFwgjHE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>

                      </TabPanel>

                      <TabPanel>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/gagana-books"><img src={require('../images/portfolio/gagana-books.png')} alt="Siddharth Naidu's Portfolio EQueris" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Gagana Books</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/gagana-books"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/equeris"><img src={require('../images/portfolio/equeris.png')} alt="Siddharth Naidu's Portfolio EQueris" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>EQueris</h3>
                                  <h4>Built with Opencart, HTML & CSS</h4>
                                  <NavLink to="/project/equeris"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/armadaa"><img src={require('../images/portfolio/armadaa.png')} alt="Siddharth Naidu's Portfolio Armadaa" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Armadaa</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/armadaa"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/vanismart"><img src={require('../images/portfolio/vanismart.png')} alt="Siddharth Naidu's Portfolio Vanismart" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Vanismart</h3>
                                  <h4>Built with WordPress, HTML & CSS</h4>
                                  <NavLink to="/project/vanismart"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                              <figure>
                                  <NavLink to="/project/blog"><img src={require('../images/portfolio/blog.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                              </figure>
                              <figcaption>
                                  <h3>Blog</h3>
                                  <h4>Built with MEAN Stack, HTML & CSS</h4>
                                  <NavLink to="/project/blog"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                              </figcaption>
                          </div>

                          <div className="project">
                            <figure>
                                <NavLink to="/project/dearadhpro"><img src={require('../images/portfolio/dearadhpro.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>DearadhPro</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/dearadhpro"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>
                        <div className="project">
                            <figure>
                                <NavLink to="/project/itor"><img src={require('../images/portfolio/itor.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>ItoR Automation</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/itor"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gensuite-csr"><img src={require('../images/portfolio/gensuite-csr.png')} alt="Siddharth Naidu's Portfolio Gensuite CSR" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gensuite CSR</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gensuite-csr"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/indus"><img src={require('../images/portfolio/indus.png')} alt="Siddharth Naidu's Portfolio Indus" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Indus</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/indus"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                      </TabPanel>

                      <TabPanel>
                        <div className="project">
                            <figure>
                                <NavLink to="/project/us-cranberries"><img src={require('../images/portfolio/us-cranberries.png')} alt="Siddharth Naidu's Portfolio US Cranberries" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>US Cranberries</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/us-cranberries"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/guru-prasadam"><img src={require('../images/portfolio/guru-prasadam.png')} alt="Siddharth Naidu's Portfolio Guru Prasadam" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Guru Prasadam</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/guru-prasadam"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/bull-india"><img src={require('../images/portfolio/bull-india.png')} alt="Siddharth Naidu's Portfolio Bull India" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Bull India</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/bull-india"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/essae"><img src={require('../images/portfolio/essae.png')} alt="Siddharth Naidu's Portfolio Essae" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Essae</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/essae"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/karlsson-leather"><img src={require('../images/portfolio/karlsson-leather.png')} alt="Siddharth Naidu's Portfolio Karlsson Leather" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Karlsson Leather</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/karlsson-leather"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/kuberan-silks"><img src={require('../images/portfolio/kuberan-silks.png')} alt="Siddharth Naidu's Portfolio Kuberan Silks" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Kuberan Silks</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/kuberan-silks"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/armadaa"><img src={require('../images/portfolio/armadaa.png')} alt="Siddharth Naidu's Portfolio Armadaa" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Armadaa</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/armadaa"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/equeris"><img src={require('../images/portfolio/equeris.png')} alt="Siddharth Naidu's Portfolio EQueris" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>EQueris</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/equeris"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gagana-books"><img src={require('../images/portfolio/gagana-books.png')} alt="Siddharth Naidu's Portfolio EQueris" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gagana Books</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gagana-books"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/vanismart"><img src={require('../images/portfolio/vanismart.png')} alt="Siddharth Naidu's Portfolio Vanismart" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Vanismart</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/vanismart"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/siddharth-naidu"><img src={require('../images/portfolio/siddharth-naidu.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Siddharth Naidu</h3>
                                <h4>Built with React JS, HTML & CSS</h4>
                                <NavLink to="/project/siddharth-naidu"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/blog"><img src={require('../images/portfolio/blog.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Blog</h3>
                                <h4>Built with MEAN Stack, HTML & CSS</h4>
                                <NavLink to="/project/blog"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/prima-baby"><img src={require('../images/portfolio/prima-baby.png')} alt="Siddharth Naidu's Portfolio Prima Baby" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Prima Baby</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/prima-baby"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/dearadhpro"><img src={require('../images/portfolio/dearadhpro.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>DearadhPro</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/dearadhpro"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>
                        <div className="project">
                            <figure>
                                <NavLink to="/project/itor"><img src={require('../images/portfolio/itor.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>ItoR Automation</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/itor"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gensuite"><img src={require('../images/portfolio/gensuite.png')} alt="Siddharth Naidu's Portfolio Gensuite" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gensuite</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gensuite"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gensuite-india"><img src={require('../images/portfolio/gensuite-india.png')} alt="Siddharth Naidu's Portfolio Gensuite India" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gensuite India</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gensuite-india"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gensuite-conference"><img src={require('../images/portfolio/gensuite-conference.png')} alt="Siddharth Naidu's Portfolio Gensuite Conference" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gensuite Conference</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gensuite-conference"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gensuite-csr"><img src={require('../images/portfolio/gensuite-csr.png')} alt="Siddharth Naidu's Portfolio Gensuite CSR" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gensuite CSR</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gensuite-csr"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gearnride"><img src={require('../images/portfolio/gearnride.png')} alt="Siddharth Naidu's Portfolio Gear N Ride" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Gear N Ride</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/gearnride"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/ibca-bangalore"><img src={require('../images/portfolio/ibca-bangalore.png')} alt="Siddharth Naidu's Portfolio IBCA" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>IBCA</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/ibca-bangalore"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/lamppost-media"><img src={require('../images/portfolio/lamppost-media.png')} alt="Siddharth Naidu's Portfolio Lamppost Media" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Lamppost Media</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/lamppost-media"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/lexington"><img src={require('../images/portfolio/lexington.png')} alt="Siddharth Naidu's Portfolio Lexington" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Lexington</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/lexington"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/elrich-life"><img src={require('../images/portfolio/elrich-life.png')} alt="Siddharth Naidu's Portfolio Elrich Life" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Elrich Life</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/elrich-life"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/abhee-nandana"><img src={require('../images/portfolio/abhee-nandana.png')} alt="Siddharth Naidu's Portfolio Abhee Nandana" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Abhee Nandana</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/abhee-nandana"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/online-king"><img src={require('../images/portfolio/online-king.png')} alt="Siddharth Naidu's Portfolio Online King" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Online King</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/online-king"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/dequalita"><img src={require('../images/portfolio/dequalita.png')} alt="Siddharth Naidu's Portfolio DeQualita" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>DeQualita</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/dequalita"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/rajashri-foods"><img src={require('../images/portfolio/rajashri-foods.png')} alt="Siddharth Naidu's Portfolio Rajashri Foods" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Rajashri Foods</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/rajashri-foods"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/essae-dig"><img src={require('../images/portfolio/essae-dig.png')} alt="Siddharth Naidu's Portfolio Essae Dig" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Essae Dig</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/essae-dig"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/aroma-milk"><img src={require('../images/portfolio/aroma-milk.png')} alt="Siddharth Naidu's Portfolio Aroma Milk" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Aroma Milk</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/aroma-milk"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/prasad-photography"><img src={require('../images/portfolio/prasad-photography.png')} alt="Siddharth Naidu's Portfolio Prasad Photography" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Prasad Photography</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/prasad-photography"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/tekcel"><img src={require('../images/portfolio/tekcel.png')} alt="Siddharth Naidu's Portfolio Tekcel" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Tekcel</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/tekcel"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/mkc-food-products"><img src={require('../images/portfolio/mkc-food-products.png')} alt="Siddharth Naidu's Portfolio MKC Food Products" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>MKC Food Products</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/mkc-food-products"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/kirthika-international"><img src={require('../images/portfolio/kirthika-international.png')} alt="Siddharth Naidu's Portfolio Kirthika International" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Kirthika International</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/kirthika-international"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/renaatus"><img src={require('../images/portfolio/renaatus.png')} alt="Siddharth Naidu's Portfolio Renaatus Projects" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Renaatus Projects</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/renaatus"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/saideep-exports"><img src={require('../images/portfolio/saideep-exports.png')} alt="Siddharth Naidu's Portfolio Saideep Exports" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Saideep Exports</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/saideep-exports"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/incite-cam-centre"><img src={require('../images/portfolio/incite-cam-centre.png')} alt="Siddharth Naidu's Portfolio Incite Cam Centre" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Incite Cam Centre</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/incite-cam-centre"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/kargil-equipments"><img src={require('../images/portfolio/kargil-equipments.png')} alt="Siddharth Naidu's Portfolio Kargil Equipments" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Kargil Equipments</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/kargil-equipments"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/sandisol"><img src={require('../images/portfolio/sandisol.png')} alt="Siddharth Naidu's Portfolio Sandisol" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Sandisol</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/sandisol"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/secuserve"><img src={require('../images/portfolio/secuserve.png')} alt="Siddharth Naidu's Portfolio Secuserve" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Secuserve</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/secuserve"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/the-talk"><img src={require('../images/portfolio/the-talk.png')} alt="Siddharth Naidu's Portfolio The Talk" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>The Talk</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/the-talk"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/tingg-ideas"><img src={require('../images/portfolio/tingg-ideas.png')} alt="Siddharth Naidu's Portfolio Tingg Ideas" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Tingg Ideas</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/tingg-ideas"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/uv-gullas"><img src={require('../images/portfolio/uv-gullas.png')} alt="Siddharth Naidu's Portfolio UN Gullas" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>UV Gullas</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/uv-gullas"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/appac-media"><img src={require('../images/portfolio/appac-media.png')} alt="Siddharth Naidu's Portfolio Appac Media" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Appac Media</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/appac-media"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/glittek-granites"><img src={require('../images/portfolio/glittek-granites.png')} alt="Siddharth Naidu's Portfolio Glittek Granites" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Glittek Granites</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/glittek-granites"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/glittek-granites"><img src={require('../images/portfolio/msrmh.png')} alt="Siddharth Naidu's Portfolio Glittek Granites" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>MSRMH</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/msrmh/"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/igauge"><img src={require('../images/portfolio/igauge.png')} alt="Siddharth Naidu's Portfolio iGauge" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>iGauge</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/igauge"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/ms-pink-panther"><img src={require('../images/portfolio/ms-pink-panther.png')} alt="Siddharth Naidu's Portfolio MS Pink Panther" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>MS Pink Panther</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/ms-pink-panther"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/nehas-bags"><img src={require('../images/portfolio/nehas-bags.png')} alt="Siddharth Naidu's Portfolio Nehas Bags" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Nehas Bags</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/nehas-bags"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/floret-media"><img src={require('../images/portfolio/floret-media.png')} alt="Siddharth Naidu's Portfolio Floret Media" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Floret Media</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/floret-media"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/dr-venus"><img src={require('../images/portfolio/dr-venus.png')} alt="Siddharth Naidu's Portfolio Dr.Venus" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Dr.Venus</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/dr-venus"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/gmg-advisors"><img src={require('../images/portfolio/gmg-advisors.png')} alt="Siddharth Naidu's Portfolio GMG Advisors" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>GMG Advisors</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/gmg-advisors"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/alankar-logistics"><img src={require('../images/portfolio/alankar-logistics.png')} alt="Siddharth Naidu's Portfolio Alankar Logistics" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Alankar Logistics</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/alankar-logistics"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/uni-gauge"><img src={require('../images/portfolio/uni-gauge.png')} alt="Siddharth Naidu's Portfolio Unigauge" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Unigauge</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/uni-gauge"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/utcom"><img src={require('../images/portfolio/utcom.png')} alt="Siddharth Naidu's Portfolio Utcom" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Utcom</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/utcom"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/comet-metallic"><img src={require('../images/portfolio/comet-metallic.png')} alt="Siddharth Naidu's Portfolio Comet Metallic" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Comet Metallic</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/comet-metallic"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/1mg-lido-mall"><img src={require('../images/portfolio/1mg-lido-mall.png')} alt="Siddharth Naidu's Portfolio 1MG Lido Mall" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>1MG Lido Mall</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/1mg-lido-mall"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/pavechas"><img src={require('../images/portfolio/pavechas.png')} alt="Siddharth Naidu's Portfolio Pavechas" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Pavechas</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/pavechas"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/geewin-exim"><img src={require('../images/portfolio/geewin-exim.png')} alt="Siddharth Naidu's Portfolio Geewin Exim" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Geewin Exim</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/geewin-exim"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/iyengars-bakery"><img src={require('../images/portfolio/iyengars-bakery.png')} alt="Siddharth Naidu's Portfolio Iyengars Bakery" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Iyengars Bakery</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/iyengars-bakery"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/caripill-micro"><img src={require('../images/portfolio/caripill-micro.png')} alt="Siddharth Naidu's Portfolio Caripill Micro" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Caripill Micro</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/caripill-micro"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/ecakoholic"><img src={require('../images/portfolio/ecakoholic.png')} alt="Siddharth Naidu's Portfolio Ecakoholic" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Ecakoholic</h3>
                                <h4>Built with Opencart, HTML & CSS</h4>
                                <NavLink to="/project/ecakoholic"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/digital-exchange"><img src={require('../images/portfolio/digital-exchange.png')} alt="Siddharth Naidu's Portfolio Digital Exchange" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Digital Exchange</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/digital-exchange"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/mta-live-events"><img src={require('../images/portfolio/mta-live-events.png')} alt="Siddharth Naidu's Portfolio MTA Live Events" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>MTA Live Events</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/mta-live-events"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/pjt-foods"><img src={require('../images/portfolio/pjt-foods.png')} alt="Siddharth Naidu's Portfolio PJT Foods" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>PJT Foods</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/pjt-foods"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/propel"><img src={require('../images/portfolio/propel.png')} alt="Siddharth Naidu's Portfolio Propel" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Propel</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/propel"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/digiqom"><img src={require('../images/portfolio/digiqom.png')} alt="Siddharth Naidu's Portfolio Digiqom" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Digiqom</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/digiqom"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/indus"><img src={require('../images/portfolio/indus.png')} alt="Siddharth Naidu's Portfolio Indus" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Indus</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/indus"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/dutyx"><img src={require('../images/portfolio/dutyx.png')} alt="Siddharth Naidu's Portfolio DutyX" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>DutyX</h3>
                                <h4>Built with HTML & CSS</h4>
                                <NavLink to="/project/dutyx"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/ps-consultants"><img src={require('../images/portfolio/ps-consultants.png')} alt="Siddharth Naidu's Portfolio PS Consultants" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>PS Consultants</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/ps-consultants"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/snm-masala"><img src={require('../images/portfolio/snm-masala.png')} alt="Siddharth Naidu's Portfolio SNM Masala" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>SNM Masala</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/snm-masala"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/mr-intelligent"><img src={require('../images/portfolio/mr-intelligent.png')} alt="Siddharth Naidu's Portfolio Mr.Intelligent" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Mr.Intelligent</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/mr-intelligent"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/namasthe-andhra"><img src={require('../images/portfolio/namasthe-andhra.png')} alt="Siddharth Naidu's Portfolio Namasthe Andhra" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Namasthe Andhra</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/namasthe-andhra"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/rentozy"><img src={require('../images/portfolio/rentozy.png')} alt="Siddharth Naidu's Portfolio Rentozy" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Rentozy</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/rentozy"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/dzinelab"><img src={require('../images/portfolio/dzinelab.png')} alt="Siddharth Naidu's Portfolio Dzinelab" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Dzinelab</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/dzinelab"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/fulcrum-strategy"><img src={require('../images/portfolio/fulcrum-strategy.png')} alt="Siddharth Naidu's Portfolio Fulcrum Strategy" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Fulcrum Strategy</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/fulcrum-strategy"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/venyaa-chemicals"><img src={require('../images/portfolio/venyaa-chemicals.png')} alt="Siddharth Naidu's Portfolio Venyaa Chemicals" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Venyaa Chemicals</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/venyaa-chemicals"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/cbc-fashions"><img src={require('../images/portfolio/cbc-fashions.png')} alt="Siddharth Naidu's Portfolio CBC Fashions" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>CBC Fashions</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/cbc-fashions"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/sam-pumps"><img src={require('../images/portfolio/sam-pumps.png')} alt="Siddharth Naidu's Portfolio Sam Pumps" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Sam Pumps</h3>
                                <h4>Built with PHP, HTML & CSS</h4>
                                <NavLink to="/project/sam-pumps"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/thrayi"><img src={require('../images/portfolio/thrayi.png')} alt="Siddharth Naidu's Portfolio Thrayi" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Thrayi</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/thrayi"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/tinakamal"><img src={require('../images/portfolio/tinakamal.png')} alt="Siddharth Naidu's Portfolio Tinakamal" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Tina Kamal</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/tinakamal"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/archies-projects"><img src={require('../images/portfolio/archies-projects.png')} alt="Siddharth Naidu's Portfolio Archies Projects" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Archies Projects</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/archies-projects"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/yaseen-overseas"><img src={require('../images/portfolio/yaseen-overseas.png')} alt="Siddharth Naidu's Portfolio Yaseen Overseas" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Yaseen Overseas</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/yaseen-overseas"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="/project/bangalore-rmc"><img src={require('../images/portfolio/bangalore-rmc.png')} alt="Siddharth Naidu's Portfolio Bangalore RMC" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Bangalore RMC</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                                <NavLink to="/project/bangalore-rmc"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="#"><img src={require('../images/portfolio/gt-infra-trade.png')} alt="Siddharth Naidu's Portfolio GT Infra Trade" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>GT Infra Trade</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="#"><img src={require('../images/portfolio/hotel-harsha.png')} alt="Siddharth Naidu's Portfolio Hotel Harsha" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Hotel Harsha</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="#"><img src={require('../images/portfolio/deccan-equipments.png')} alt="Siddharth Naidu's Portfolio Deccan Equipments" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Deccan Equipments</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                            </figcaption>
                        </div>

                        <div className="project">
                            <figure>
                                <NavLink to="#"><img src={require('../images/portfolio/citizen-builders.png')} alt="Siddharth Naidu's Portfolio Citizen Builders" /></NavLink>
                            </figure>
                            <figcaption>
                                <h3>Citizen Builders</h3>
                                <h4>Built with WordPress, HTML & CSS</h4>
                            </figcaption>
                        </div>
                      </TabPanel>

                  </Tabs>

                </div>
            </div>

      </div>
    )
  }
