import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';
import Pdf from '../siddharth-naidu-cv.pdf';
import TypeWriterEffect from 'react-typewriter-effect';

class Contact extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Contact | Siddharth Frontend Website Developer with 5 years of Experience</title>
        </Helmet>

        <div className="contactMe">

          <div className="banner">
              <img src={require('../images/banners/about-me.jpg')} alt="Siddharth" title="Siddharth" />
              <Fade bottom duration={500} delay={200}><h3>Contact</h3></Fade>
              <Fade bottom duration={500} delay={200}><h4>Want to get in touch? i'm happy to hear from you.</h4></Fade>
          </div>

          <div className="contact-me">
            <div className="container">

              <div className="contact">
                <h2>Want to get in touch?<br/>Find me on 
                <TypeWriterEffect
                  startDelay={10}
                  cursorColor="#999999"
                  multiText={[
                    'Facebook',
                    'Instagram',
                    'Twitter',
                    'Linkedin',
                    'Whatsapp',
                    'Facebook',
                    'Instagram',
                    'Twitter',
                    'Linkedin',
                    'Whatsapp',
                    'Facebook',
                    'Instagram',
                    'Twitter',
                    'Linkedin',
                    'Whatsapp',
                  ]}
                  multiTextDelay={3000}
                  typeSpeed={150}
                />
                or <a href="mailto:hello@siddharthnaidu.com">email</a>, <a href="https://www.facebook.com/SiddharthNaidu.Pagadala/" target="_blank">phone.</a>
                </h2>

                <p><a href="mailto:hello@siddharthnaidu.com"><i class="fa fa-envelope" aria-hidden="true"></i> hello@siddharthnaidu.com</a></p>
                <p><a href="tel:9948400800"><i class="fa fa-phone-square" aria-hidden="true"></i> +91 9948 400 800</a></p>
                <div className="social-icons">
                    <ul>
                    <li><a href="https://www.facebook.com/SiddharthNaidu.Pagadala/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                    <li><a href="https://www.instagram.com/SiddharthNaidu.Pagadala/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                    <li><a href="https://in.pinterest.com/SiddharthNaiduPagadala/_saved/" target="_blank"><i class="fa fa-pinterest" aria-hidden="true"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/siddharthnaidupagadala/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                    <li><a href="https://www.behance.net/siddharth_naidu" target="_blank"><i class="fa fa-behance" aria-hidden="true"></i></a></li>
                    <li><a href="https://soundcloud.com/siddharth_naidu" target="_blank"><i class="fa fa-soundcloud" aria-hidden="true"></i></a></li>
                    <li><a href="https://wa.me/+919948400800" target="_blank"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
                <NavLink to={Pdf} target="_blank" className="btn">Download CV</NavLink>
              </div>

              <div className="contactAvatar">
                  <figure>
                      <img src={require('../images/siddharth-naidu.jpg')} alt="Siddharth Naidu" title="Siddharth Naidu" />
                  </figure>
              </div>

              <h3>Come Grab a Beer</h3>

              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15552.455319846456!2d77.72623298271095!3d12.964567297601677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11f35d0dfc83%3A0x30cfa512d80115f9!2sWhitefield%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1700201744816!5m2!1sen!2sin" width="100%" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
          </div>

        </div>

      </div>
    )
  }

}

export default Contact;
