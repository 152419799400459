import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


class Guntur2016 extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Guntur Trip</title>
        </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/photos.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            <h3>Guntur</h3>
            <h4>When i'm not creating websites, i love to travel.</h4>
        </div>

        <div className="photos">
          <div className="container">

            <div className="mainWrapper">
                  <img src={require('../../images/trips/guntur-2016/siddharth-guntur-trip-02.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur-2016/siddharth-guntur-trip-01.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                  <img src={require('../../images/trips/guntur-2016/siddharth-guntur-trip-03.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            </div>

          </div>
        </div>

      </div>
    )
  }

}

export default Guntur2016;
