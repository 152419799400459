import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';

class Notfound extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>404 page not found | Siddharth Frontend Website Developer with 5 years of Experience</title>
        </Helmet>

        <div className="contactMe">

          <div className="banner">
              <img src={require('../images/banners/portfolio.jpg')} alt="Siddharth" title="Siddharth" />
              <Fade bottom duration={500} delay={200}><h3>404</h3></Fade>
              <Fade bottom duration={500} delay={200}><h4>Page not found.</h4></Fade>
          </div>

          <div className="error">
              <h2>Awwww... it's just a 404 error!</h2>
              <p>What you're looking for may have been misplaced, try searching again.</p>
          </div>

        </div>

      </div>
    )
  }

}

export default Notfound;
