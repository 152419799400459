import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

import Home from './home';
import About from './about';
import Portfolio from './portfolio';
import Photography from './photography';
import Movies from './movies';
import Cooking from './cooking';
import Sitemap from './sitemap';
import Siddharthnaidu from './project/siddharth-naidu';
import Blog from './project/blog';
import Photos from './photos';
import Contact from './contact';
import Notfound from './not-found';
import Mglidomall from './project/1mg-lido-mall';
import Caripillmicro from './project/caripill-micro';
import Cbcfashions from './project/cbc-fashions';
import Digitalexchange from './project/digital-exchange';
import Ecakoholic from './project/ecakoholic';
import Gearnride from './project/gearnride';
import Dearadhpro from './project/dearadhpro';
import Itor from './project/itor';
import Guruprasadam from './project/guru-prasadam';
import Onlineking from './project/online-king';
import Pavechas from './project/pavechas';
import Armadaa from './project/armadaa';
import Digiqom from './project/digiqom';
import Dzinelab from './project/dzinelab';
import Equeris from './project/equeris';
import Fulcrumstrategy from './project/fulcrum-strategy';
import Gaganabooks from './project/gagana-books';
import Gensuitecsr from './project/gensuite-csr';
import Indus from './project/indus';
import Prasadphotography from './project/prasad-photography';
import Rentozy from './project/rentozy';
import Vanismart from './project/vanismart';
import Venyaachemicals from './project/venyaa-chemicals';
import Sampumps from './project/sam-pumps';
import Thrayi from './project/thrayi';
import Abheenandana from './project/abhee-nandana';
import Alankarlogistics from './project/alankar-logistics';
import Appacmedia from './project/appac-media';
import Archiesprojects from './project/archies-projects';
import Aromamilk from './project/aroma-milk';
import Bangalorermc from './project/bangalore-rmc';
import Bullindia from './project/bull-india';
import Cometmetallic from './project/comet-metallic';
import Dequalita from './project/dequalita';
import Drvenus from './project/dr-venus';
import Dutyx from './project/dutyx';
import Elrichlife from './project/elrich-life';
import Essaedig from './project/essae-dig';
import Essae from './project/essae';
import Floretmedia from './project/floret-media';
import Geewinexim from './project/geewin-exim';
import Gensuiteconference from './project/gensuite-conference';
import Gensuiteindia from './project/gensuite-india';
import Gensuite from './project/gensuite';
import Glittekgranites from './project/glittek-granites';
import Gmgadvisors from './project/gmg-advisors';
import Ibcabangalore from './project/ibca-bangalore';
import Igauge from './project/igauge';
import Incitecamcentre from './project/incite-cam-centre';
import Iyengarsbakery from './project/iyengars-bakery';
import Kargilequipments from './project/kargil-equipments';
import Karlssonleather from './project/karlsson-leather';
import Kirthikainternational from './project/kirthika-international';
import Kuberansilks from './project/kuberan-silks';
import Lamppostmedia from './project/lamppost-media';
import Lexington from './project/lexington';
import Mkcfoodproducts from './project/mkc-food-products';
import Mrintelligent from './project/mr-intelligent';
import Mspinkpanther from './project/ms-pink-panther';
import Mtaliveevents from './project/mta-live-events';
import Namastheandhra from './project/namasthe-andhra';
import Nehasbags from './project/nehas-bags';
import Pjtfoods from './project/pjt-foods';
import Primababy from './project/prima-baby';
import Propel from './project/propel';
import Psconsultants from './project/ps-consultants';
import Rajashrifoods from './project/rajashri-foods';
import Renaatus from './project/renaatus';
import Saideepexports from './project/saideep-exports';
import Sandisol from './project/sandisol';
import Secuserve from './project/secuserve';
import Snmmasala from './project/snm-masala';
import Tinggideas from './project/tingg-ideas';
import Tekcel from './project/tekcel';
import Thetalk from './project/the-talk';
import Tinakamal from './project/tinakamal';
import Msrmh from './project/msrmh';
import Unigauge from './project/uni-gauge';
import Uscranberries from './project/us-cranberries';
import Utcom from './project/utcom';
import Uvgullas from './project/uv-gullas';
import Yaseenoverseas from './project/yaseen-overseas';
import Mysuru from './trip/mysuru';
import Amaravati from './trip/amaravati';
import Nandihills from './trip/nandi-hills';
import Tirupati from './trip/tirupati';
import Coorg from './trip/coorg';
import Sakleshpur from './trip/sakleshpur';
import Bannerghatta from './trip/bannerghatta-zoo';
import Chikmagalur from './trip/chikmagalur';
import Bigbanyantree from './trip/big-banyan-tree';
import Vidhanasoudha from './trip/vidhana-soudha';
import Guntur from './trip/guntur';
import Guntur2016 from './trip/guntur-2016';
import Vijayawada from './trip/vijayawada';
import Ooty from './trip/ooty';
import Wonderla from './trip/wonderla';
import Palamaner from './trip/palamaner';
import Yelahanka from './trip/yelahanka';
import Salem from './trip/salem';
import Nandihills2017 from './trip/nandi-hills-2017';

const Main = () => (
  <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/portfolio" component={Portfolio} />
      <Route path="/hobbie/photography" component={Photography} />
      <Route path="/hobbie/movies" component={Movies} />
      <Route path="/hobbie/cooking" component={Cooking} />
      <Route path="/sitemap" component={Sitemap} />
      <Route path="/project/siddharth-naidu" component={Siddharthnaidu} />
      <Route path="/project/blog" component={Blog} />
      <Route path="/photos" component={Photos} />
      <Route path="/contact" component={Contact} />
      <Route path="/project/1mg-lido-mall" component={Mglidomall} />
      <Route path="/project/caripill-micro" component={Caripillmicro} />
      <Route path="/project/cbc-fashions" component={Cbcfashions} />
      <Route path="/project/digital-exchange" component={Digitalexchange} />
      <Route path="/project/ecakoholic" component={Ecakoholic} />
      <Route path="/project/armadaa" component={Armadaa} />
      <Route path="/project/digiqom" component={Digiqom} />
      <Route path="/project/dzinelab" component={Dzinelab} />
      <Route path="/project/equeris" component={Equeris} />
      <Route path="/project/fulcrum-strategy" component={Fulcrumstrategy} />
      <Route path="/project/gagana-books" component={Gaganabooks} />
      <Route path="/project/gensuite-csr" component={Gensuitecsr} />
      <Route path="/project/indus" component={Indus} />
      <Route path="/project/prasad-photography" component={Prasadphotography} />
      <Route path="/project/rentozy" component={Rentozy} />
      <Route path="/project/vanismart" component={Vanismart} />
      <Route path="/project/venyaa-chemicals" component={Venyaachemicals} />
      <Route path="/project/gearnride" component={Gearnride} />
      <Route path="/project/dearadhpro" component={Dearadhpro} />
      <Route path="/project/itor" component={Itor} />
      <Route path="/project/guru-prasadam" component={Guruprasadam} />
      <Route path="/project/online-king" component={Onlineking} />
      <Route path="/project/pavechas" component={Pavechas} />
      <Route path="/project/sam-pumps" component={Sampumps} />
      <Route path="/project/thrayi" component={Thrayi} />
      <Route path="/project/us-cranberries" component={Uscranberries} />
      <Route path="/project/abhee-nandana" component={Abheenandana} />
      <Route path="/project/alankar-logistics" component={Alankarlogistics} />
      <Route path="/project/appac-media" component={Appacmedia} />
      <Route path="/project/archies-projects" component={Archiesprojects} />
      <Route path="/project/aroma-milk" component={Aromamilk} />
      <Route path="/project/bangalore-rmc" component={Bangalorermc} />
      <Route path="/project/bull-india" component={Bullindia} />
      <Route path="/project/comet-metallic" component={Cometmetallic} />
      <Route path="/project/dequalita" component={Dequalita} />
      <Route path="/project/dr-venus" component={Drvenus} />
      <Route path="/project/dutyx" component={Dutyx} />
      <Route path="/project/elrich-life" component={Elrichlife} />
      <Route path="/project/essae-dig" component={Essaedig} />
      <Route path="/project/essae" component={Essae} />
      <Route path="/project/floret-media" component={Floretmedia} />
      <Route path="/project/geewin-exim" component={Geewinexim} />
      <Route path="/project/gensuite-conference" component={Gensuiteconference} />
      <Route path="/project/gensuite-india" component={Gensuiteindia} />
      <Route path="/project/gensuite" component={Gensuite} />
      <Route path="/project/glittek-granites" component={Glittekgranites} />
      <Route path="/project/gmg-advisors" component={Gmgadvisors} />
      <Route path="/project/ibca-bangalore" component={Ibcabangalore} />
      <Route path="/project/igauge" component={Igauge} />
      <Route path="/project/incite-cam-centre" component={Incitecamcentre} />
      <Route path="/project/iyengars-bakery" component={Iyengarsbakery} />
      <Route path="/project/kargil-equipments" component={Kargilequipments} />
      <Route path="/project/karlsson-leather" component={Karlssonleather} />
      <Route path="/project/kirthika-international" component={Kirthikainternational} />
      <Route path="/project/kuberan-silks" component={Kuberansilks} />
      <Route path="/project/lamppost-media" component={Lamppostmedia} />
      <Route path="/project/lexington" component={Lexington} />
      <Route path="/project/mkc-food-products" component={Mkcfoodproducts} />
      <Route path="/project/mr-intelligent" component={Mrintelligent} />
      <Route path="/project/ms-pink-panther" component={Mspinkpanther} />
      <Route path="/project/mta-live-events" component={Mtaliveevents} />
      <Route path="/project/namasthe-andhra" component={Namastheandhra} />
      <Route path="/project/nehas-bags" component={Nehasbags} />
      <Route path="/project/pjt-foods" component={Pjtfoods} />
      <Route path="/project/prima-baby" component={Primababy} />
      <Route path="/project/propel" component={Propel} />
      <Route path="/project/ps-consultants" component={Psconsultants} />
      <Route path="/project/rajashri-foods" component={Rajashrifoods} />
      <Route path="/project/renaatus" component={Renaatus} />
      <Route path="/project/saideep-exports" component={Saideepexports} />
      <Route path="/project/sandisol" component={Sandisol} />
      <Route path="/project/secuserve" component={Secuserve} />
      <Route path="/project/snm-masala" component={Snmmasala} />
      <Route path="/project/tingg-ideas" component={Tinggideas} />
      <Route path="/project/tekcel" component={Tekcel} />
      <Route path="/project/the-talk" component={Thetalk} />
      <Route path="/project/tinakamal" component={Tinakamal} />
      <Route path="/project/msrmh" component={Msrmh} />
      <Route path="/project/uni-gauge" component={Unigauge} />
      <Route path="/project/utcom" component={Utcom} />
      <Route path="/project/uv-gullas" component={Uvgullas} />
      <Route path="/project/yaseen-overseas" component={Yaseenoverseas} />
      <Route path="/trip/mysuru" component={Mysuru} />
      <Route path="/trip/amaravati" component={Amaravati} />
      <Route path="/trip/nandi-hills" component={Nandihills} />
      <Route path="/trip/tirupati" component={Tirupati} />
      <Route path="/trip/coorg" component={Coorg} />
      <Route path="/trip/sakleshpur" component={Sakleshpur} />
      <Route path="/trip/chikmagalur" component={Chikmagalur} />
      <Route path="/trip/big-banyan-tree" component={Bigbanyantree} />
      <Route path="/trip/bannerghatta-zoo" component={Bannerghatta} />
      <Route path="/trip/vidhana-soudha" component={Vidhanasoudha} />
      <Route path="/trip/guntur" component={Guntur} />
      <Route path="/trip/guntur-2016" component={Guntur2016} />
      <Route path="/trip/vijayawada" component={Vijayawada} />
      <Route path="/trip/ooty" component={Ooty} />
      <Route path="/trip/wonderla" component={Wonderla} />
      <Route path="/trip/palamaner" component={Palamaner} />
      <Route path="/trip/yelahanka" component={Yelahanka} />
      <Route path="/trip/salem" component={Salem} />
      <Route path="/trip/nandi-hills-2017" component={Nandihills2017} />
      <Route component={Notfound} />
  </Switch>
)

export default Main;
