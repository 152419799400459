import React, { useState, useEffect } from "react";
import Main from './components/main';
import { NavLink } from 'react-router-dom';
import Slide from 'react-reveal/Slide';
import { slide as Menu } from 'react-burger-menu';
import Pdf from './siddharth-naidu-cv.pdf';
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider } from "react-lightgallery";
import { LightgalleryItem } from "react-lightgallery";

export default function Header(props) {
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50)
    })
  }, [])
  return ( 
    <div className="App">
      <header className={scroll ? "main-header scrolled" : "main-header"}>
        <div className="container">
          <div className="logo">
            <NavLink to="/" title="Siddharth Naidu Pagadala">
              <img src={require('./images/logo.svg')} alt="Siddharth Naidu Pagadala" title="Siddharth Naidu Pagadala" />
              <h3>Siddharth<span>.</span></h3>
              <p>Frontend Website Developer</p>
            </NavLink>
          </div>
          <div className="menu">
            <ul>
              <Slide bottom duration={500} delay={100}>
                <li>
                  <NavLink to="/about">
                    <span>01.</span>
                    <span>About me</span>
                    <span>A little about who i am and what i enjoy doing.</span>
                  </NavLink>
                  <NavLink to="/hobbie"></NavLink>
                </li>
              </Slide>
              <Slide bottom delay={200}>
                <li>
                  <NavLink to="/portfolio">
                    <span>02.</span>
                    <span>Portfolio</span>
                    <span>Some projects i have worked on.</span>
                  </NavLink>
                  <NavLink to="/project"></NavLink>
                </li>
              </Slide>
              <Slide bottom delay={300}>
                <li>
                  <NavLink to="/photos">
                    <span>03.</span>
                    <span>Photos</span>
                    <span>When i'm not creating websites, i love to travel.</span>
                  </NavLink>
                  <NavLink to="/trip"></NavLink>
                </li>
              </Slide>
              <Slide bottom delay={300}>
                <li>
                  <a href="https://blog.siddharthnaidu.com" target="_blank">
                    <span>04.</span>
                    <span>Blog</span>
                    <span>Some of my thoughts on Web development.</span>
                  </a>
                  <NavLink to="/post"></NavLink>
                </li>
              </Slide>
              <Slide bottom delay={500}><li><NavLink to="/contact"><span>05.</span><span>Contact</span><span>Take some time to say hello.</span></NavLink></li></Slide>
            </ul>
          </div>
          <div class="navBtn">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Menu enableCloseOnEsc right enableOverlayClick isOpen={false}>
            <NavLink to="">Home</NavLink>
            <NavLink to="/about">About me</NavLink>
            <NavLink to={Pdf} target="_blank">Resume</NavLink>
            <NavLink to="/portfolio">Portfolio</NavLink>
            <NavLink to="/photos">Photos</NavLink>
            <a href="https://blog.siddharthnaidu.com" target="_blank">Blog</a>
            <NavLink to="/contact">Contact</NavLink>
          </Menu>
        </div>
      </header>

      <LightgalleryProvider>

        <div className="wrapper">
          <Main />
        </div>

        <footer>
          <div className="container">

            <div className="logo">
              <NavLink to="/" title="Siddharth">
                <img src={require('./images/logo-light.svg')} alt="Siddharth Naidu Pagadala" title="Siddharth Naidu Pagadala" />
                <h3>Siddharth<span>.</span></h3>
                <p>Frontend Website Developer</p>
              </NavLink>
              <div className="contactme">
                <p>Need an update or fresh responsive mobile-ready website?</p>
                <p>Get in touch :</p>
                <a href="tel:9948400800" className="btn"><i class="fa fa-phone" aria-hidden="true"></i> +91 9948 400 800</a>
                <a href="mailto:hello@siddharthnaidu.com" className="btn"><i class="fa fa-envelope" aria-hidden="true"></i> hello@siddharthnaidu.com</a>
              </div>
            </div>

            <div className="links">
              <div>
                <h4>Useful Links</h4>
                <ul className="navigation">
                  <li><NavLink to="">Home</NavLink></li>
                  <li><NavLink to="/about">About me</NavLink></li>
                  <li><NavLink to={Pdf} target="_blank">Resume</NavLink></li>
                  <li><NavLink to="/portfolio">Portfolio</NavLink></li>
                  <li><NavLink to="/photos">Photos</NavLink></li>
                  <li><a href="https://blog.siddharthnaidu.com" target="_blank">Blog</a></li>
                  <li><NavLink to="/contact">Contact</NavLink></li>
                </ul>
              </div>
              <div>
                <h4>Hobbies</h4>
                <ul>
                  <li><NavLink to="/photos">Travelling</NavLink></li>
                  <li><NavLink to="/hobbie/photography">Photography</NavLink></li>
                  <li><NavLink to="/hobbie/cooking">Cooking</NavLink></li>
                  <li><NavLink to="/hobbie/movies">Nextfilx Movies</NavLink></li>
                </ul>
                <h4><NavLink to="/sitemap">Sitemap</NavLink></h4>
                <h4><NavLink to="/contact">Contact</NavLink></h4>
              </div>
            </div>

            <div className="extralinks">
              <h4>Photography</h4>
              <div className="gallery">

                <LightgalleryItem src={require('./images/photography/max/siddharth-sakleshpur-trip.png')}>
                  <img src={require('./images/photography/siddharth-sakleshpur-trip.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                </LightgalleryItem>

                <LightgalleryItem src={require('./images/photography/max/siddharth-tirupati-trip.png')}>
                  <img src={require('./images/photography/siddharth-tirupati-trip.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                </LightgalleryItem>

                <LightgalleryItem src={require('./images/photography/max/siddharth-vidhana-soudha-trip.png')}>
                  <img src={require('./images/photography/siddharth-vidhana-soudha-trip.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                </LightgalleryItem>

                <LightgalleryItem src={require('./images/photography/max/mysore-helicopter.jpg')}>
                  <img src={require('./images/photography/mysore-helicopter.jpg')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                </LightgalleryItem>

                <LightgalleryItem src={require('./images/photography/max/siddharth-bangalore-palace.jpg')}>
                  <img src={require('./images/photography/siddharth-bangalore-palace.jpg')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                </LightgalleryItem>

                <LightgalleryItem src={require('./images/photography/max/siddharth-bannerghatta-zoo-trip.png')}>
                  <img src={require('./images/photography/siddharth-bannerghatta-zoo-trip.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                </LightgalleryItem>

                <LightgalleryItem src={require('./images/photography/max/siddharth-dosa.jpg')}>
                  <img src={require('./images/photography/siddharth-dosa.jpg')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                </LightgalleryItem>

                <LightgalleryItem src={require('./images/photography/max/siddharth-iphone.jpg')}>
                  <img src={require('./images/photography/siddharth-iphone.jpg')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                </LightgalleryItem>

                <LightgalleryItem src={require('./images/photography/max/siddharth-mysuru-temple.png')}>
                  <img src={require('./images/photography/siddharth-mysuru-temple.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                </LightgalleryItem>

                <LightgalleryItem src={require('./images/photography/max/siddharth-mysuru-trip.png')}>
                  <img src={require('./images/photography/siddharth-mysuru-trip.png')} alt="Siddharth Naidu's Photography" title="Siddharth Naidu's Photography" />
                </LightgalleryItem>

              </div>
            </div>

            <audio className="music" src={require('../src/music/background-music.ogg')} controls autoPlay loop />
          </div>
        </footer>

      </LightgalleryProvider>

      <div className="copyright">
        <div className="container">
          <div className="madewith">
            <p><a href="https://www.siddharthnaidu.com">SiddharthNaidu.com</a> made with React JS, HTML5 & CSS3, Of course losts of <i class="fa fa-heart" aria-hidden="true"></i></p>
          </div>
          <div className="goTop">
            <ul className="social-icons">
              <li>Follow me on : </li>
              <li><a href="https://www.facebook.com/SiddharthNaidu.Pagadala/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="https://www.instagram.com/SiddharthNaidu.Pagadala/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
              <li><a href="https://in.pinterest.com/SiddharthNaiduPagadala/_saved/" target="_blank"><i class="fa fa-pinterest" aria-hidden="true"></i></a></li>
              <li><a href="https://www.linkedin.com/in/siddharthnaidupagadala/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
              <li><a href="https://www.behance.net/siddharth_naidu" target="_blank"><i class="fa fa-behance" aria-hidden="true"></i></a></li>
              <li><a href="https://soundcloud.com/siddharth_naidu" target="_blank"><i class="fa fa-soundcloud" aria-hidden="true"></i></a></li>
              <li><a href="https://wa.me/+919948400800" target="_blank"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
            </ul>
            <p><a href="#">Go top <i class="fa fa-arrow-up" aria-hidden="true"></i></a></p>
          </div>
        </div>
      </div>

    </div>
  );
}
