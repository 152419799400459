import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import MernCertificate from '../certificate-mern-stack.pdf';
import MeanCertificate from '../certificate-mean-stack.pdf';
import TypeWriterEffect from 'react-typewriter-effect';
import { LightgalleryItem } from "react-lightgallery";

class About extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="app">

        <Helmet>
          <title>About me | Siddharth Frontend Website Developer with 5 years of Experience</title>
        </Helmet>

        <div className="banner">
            <img src={require('../images/banners/about-me.jpg')} alt="Siddharth" title="Siddharth" />
            <Fade bottom duration={500} delay={200}><h3>About me</h3></Fade>
            <Fade bottom duration={500} delay={200}><h4>Learn the things I enjoy the most apart from web development.</h4></Fade>
        </div>

        <div className="aboutme">
          <div className="container">
            <Fade top>
              <figure>
                <NavLink to="/contact"><img src={require('../images/siddharth-naidu.jpg')} alt="Siddharth Naidu" title="Siddharth Naidu" /></NavLink>
              </figure>
            </Fade>
            <h2>Hi <span className="emoji">👋 </span> I'm          
              <TypeWriterEffect
                startDelay={10}
                cursorColor="#ffffff"
                multiText={[
                  'Siddharth Naidu',
                  'సిద్ధార్థ్ నాయుడు',
                  'सिद्धार्थ नायडू',
                  'ಸಿದ್ಧಾರ್ಥ್ ನಾಯ್ಡು',
                  'সিদ্ধার্থ নাইডু',
                  'சித்தார்த் நாயுடு',
                  '悉达多·奈杜',
                  'シッダールスナイドゥ',
                  'Сиддхарт Найду',
                  '싯다르트 나이두',
                  'สิทธารถ ไนดู',
                  'Siddharth Naidu',
                ]}
                multiTextDelay={3000}
                typeSpeed={150}
              /><span>.</span></h2>
            <p>I'm a frontend web developer with a wide range of skills. I specialize in frontend website development and have an eye for a clean, simple design that grabs the user's attention. I have created 80+ websites in my five years of experience in website development.</p>
            <NavLink to="/contact/" className="btn"><i class="fa fa-envelope" aria-hidden="true"></i> Contact Me</NavLink>
          </div>
        </div>

        <div className="about-gallery">

            <LightgalleryItem src={require('../images/at-me/max/siddharth-macbook-air.jpg')}>
              <img src={require('../images/at-me/siddharth-macbook-air.jpg')} alt="Siddharth Naidu's Macbook (Macbook Air M1)" title="Siddharth Naidu's Macbook (M1 Macbook Air)" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-iphone.jpg')}>
                <img src={require('../images/at-me/siddharth-iphone.jpg')} alt="Siddharth Naidu's iPhone (iPhone 13 Pro)" title="Siddharth Naidu's iPhone (iPhone 13 Pro)" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-coorg-trip.jpg')}>
                <img src={require('../images/at-me/siddharth-coorg-trip.jpg')} alt="Coorg Trip" title="Coorg Trip" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-computer.jpg')}>
                <img src={require('../images/at-me/siddharth-computer.jpg')} alt="Siddharth Naidu's Computer" title="Siddharth Naidu's Computer" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-balcony.jpg')}>
                <img src={require('../images/at-me/siddharth-balcony.jpg')} alt="Siddharth Naidu's Balcony  (Siddharth's Photography)" title="Siddharth Naidu's Balcony  (Siddharth's Photography)" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-bangalore-palace.jpg')}>
                <img src={require('../images/at-me/siddharth-bangalore-palace.jpg')} alt="Bangalore Palace  (Siddharth's Photography)" title="Bangalore Palace  (Siddharth's Photography)" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-fridge.jpg')}>
                <img src={require('../images/at-me/siddharth-fridge.jpg')} alt="Siddharth Naidu's Fridge (Siddharth's Photography)" title="Siddharth Naidu's Fridge (Siddharth's Photography)" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-bike.jpg')}>
                <img src={require('../images/at-me/siddharth-bike.jpg')} alt="Siddharth Naidu's Bike (Siddharth's Photography)" title="Siddharth Naidu's Bike (Siddharth's Photography)" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-sweets.jpg')}>
                <img src={require('../images/at-me/siddharth-sweets.jpg')} alt="Siddharth Naidu's Sweets (Siddharth's Photography)" title="Siddharth Naidu's Sweets (Siddharth's Photography)" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/mysore-helicopter.jpg')}>
                <img src={require('../images/at-me/mysore-helicopter.jpg')} alt="Helicopter Ride at Mysore Palace" title="Helicopter Ride at Mysore Palace" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-tv.jpg')}>
                <img src={require('../images/at-me/siddharth-tv.jpg')} alt="Siddharth Naidu's TV (Siddharth's Photography)" title="Siddharth Naidu's TV (Siddharth's Photography)" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/bangalore-zoo.jpg')}>
                <img src={require('../images/at-me/bangalore-zoo.jpg')} alt="At Bangalore Zoo (Siddharth's Photography)" title="At Bangalore Zoo (Siddharth's Photography)" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-umbrella.jpg')}>
                <img src={require('../images/at-me/siddharth-umbrella.jpg')} alt="Siddharth Naidu's Umbrella" title="Siddharth Naidu's Umbrella" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-dosa.jpg')}>
                <img src={require('../images/at-me/siddharth-dosa.jpg')} alt="Huge Masala Dosa (Siddharth's Photography)" title="Huge Masala Dosa (Siddharth's Photography)" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-drink.jpg')}>
                <img src={require('../images/at-me/siddharth-drink.jpg')} alt="Siddharth Naidu's Drink (Siddharth's Photography)" title="Siddharth Naidu's Drink (Siddharth's Photography)" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-colleagues.jpg')}>
                <img src={require('../images/at-me/siddharth-colleagues.jpg')} alt="Siddharth Naidu's Colleagues" title="Siddharth Naidu's Colleagues" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-family.jpg')}>
                <img src={require('../images/at-me/siddharth-family.jpg')} alt="Siddharth Naidu's Family" title="Siddharth Naidu's Family" />
            </LightgalleryItem>

            <LightgalleryItem src={require('../images/at-me/max/siddharth-friends.jpg')}>
                <img src={require('../images/at-me/siddharth-friends.jpg')} alt="Siddharth Naidu's Friends" title="Siddharth Naidu's Friends" />
            </LightgalleryItem>

        </div>

        <div className="facts">
          <div className="container">
              <h2>A few facts about me</h2>
              <div className="sports fact">
                  <h4>Playing Cricket.</h4>
                  <Fade bottom duration={500} delay={200}><figure><img src={require('../images/facts/cricket.png')} alt="Siddharth Naidu's Friends" title="Siddharth Naidu's Friends" /></figure></Fade>
                  <p>Cricket is my favorite hobby and a famous sport too. It makes me feel healthy, fit and a stronger person.</p>
              </div>
              <div className="beer fact">
                  <h4>Social Beverages are good. Get Em' in.</h4>
                  <Fade bottom duration={500} delay={200}><figure><img src={require('../images/facts/beer.png')} alt="Siddharth Naidu's Friends" title="Siddharth Naidu's Friends" /></figure></Fade>
                  <p>I enjoy socialising (sensibly) on the weekends with a refreshing beer or two!</p>
              </div>
              <div className="photography fact">
                  <h4>Photography is My Passion.</h4>
                  <Fade bottom duration={500} delay={200}><figure><img src={require('../images/facts/camera.png')} alt="Siddharth Naidu's Friends" title="Siddharth Naidu's Friends" /></figure></Fade>
                  <p>Having fun while capturing memories is my favorite thing. Infact i've uploaded 250+ travel pictures in this website.</p>
              </div>
              <div className="travelling fact">
                  <h4>I Love Travelling.</h4>
                  <Fade bottom duration={500} delay={200}><figure><img src={require('../images/facts/flight.png')} alt="Siddharth Naidu's Friends" title="Siddharth Naidu's Friends" /></figure></Fade>
                  <p>When i'm not creating websites, i love to travel and explore new people and culture.</p>
              </div>
              <div className="movies fact">
                  <h4>Watching Movies or Netflix.</h4>
                  <Fade bottom duration={500} delay={200}><figure><img src={require('../images/facts/play.png')} alt="Siddharth Naidu's Friends" title="Siddharth Naidu's Friends" /></figure></Fade>
                  <p>Among all, the most common hobby i have Watching Movies and Netflix series. THE 100 is my all time favorite Netfix TV show. I strongly suggest you to watch the show.</p>
              </div>
              <div className="cooking fact">
                  <h4>Cooking is my favorite hobbie.</h4>
                  <Fade bottom duration={500} delay={200}><figure><img src={require('../images/facts/cooking.png')} alt="Siddharth Naidu's Friends" title="Siddharth Naidu's Friends" /></figure></Fade>
                  <p>Perfect for anybody who enjoys eating and likes to experiment with new and creative flavors. Included all my recent dishes in cooking page.</p>
              </div>
              <div className="smiley">
                  <Fade bottom duration={500} delay={200}><figure><img src={require('../images/facts/smiling-face.png')} alt="Siddharth Naidu's Friends" title="Siddharth Naidu's Friends" /></figure></Fade>
              </div>
          </div>
        </div>

        <div className="quote">
            <img src={require('../images/banners/portfolio.jpg')} alt="Siddharth" title="Siddharth" />
            <div className="container">
                <h2>" The only source of knowledge is experience."</h2>
                <h3>- Albert Einstein</h3>
                <p>I'm continuously trying to better myself and still get challenged (you could say frustrated...) on a daily basis. Want to know what experience and technical skills I have acquired so far?</p>
            </div>
        </div>

        <div className="skills offer">
          <div className="container">
            <h2>My Skills</h2>
            <div className="mySkills">
              <div className="html">
                <h3>
                  <CountUp start={10} end={100} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>HTML5, CSS3</p>
              </div>
              <div className="css">
                <h3>
                  <CountUp start={10} end={90} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>Angular & React JS</p>
              </div>
              <div className="bootstrap">
                <h3>
                  <CountUp start={10} end={90} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>Wordpress, Opencart</p>
              </div>
              <div className="reactjs">
                <h3>
                  <CountUp start={10} end={90} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>Bootstrap & SASS</p>
              </div>
              <div className="javascript">
                <h3>
                  <CountUp start={10} end={90} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>Javascript, JQuery</p>
              </div>
              <div className="photoshop">
                <h3>
                  <CountUp start={10} end={80} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>Adobe XD & Figma</p>
              </div>
              <div className="wordpress">
                <h3>
                  <CountUp start={10} end={80} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>React Native</p>
              </div>
              <div className="sass">
                <h3>
                  <CountUp start={10} end={80} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>Photoshop, illustrator</p>
              </div>
              <div className="wordpress">
                <h3>
                  <CountUp start={10} end={80} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>Magento</p>
              </div>
              <div className="jquery">
                <h3>
                  <CountUp start={10} end={80} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>SEO</p>
              </div>
              <div className="wordpress">
                <h3>
                  <CountUp start={10} end={100} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>Responsive Website</p>
              </div>
              <div className="maintenance">
                <h3>
                  <CountUp start={10} end={100} duration={3} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p>Website Maintenance</p>
              </div>
            </div>
          </div>
        </div>

        <div className="education">
          <div className="container">
            <h2>Education</h2>
            <img src={require('../images/education-bg.jpg')} alt="Siddharth Naidu's Education" title="Siddharth Naidu's Education" />
            <div className="eduinfo">
              <h4>MEAN Stack Web Development Course in Udemy</h4>
              <h5>Sep 2019 - June 2021</h5>
              <p>The course is a taught by the lead instructor Maximilian Schwarzmüller. Max has gone through great lengths to create great content and this course has been great. His teaching style covers everything from basic to advanced topics, and his explanations have been thorough on each topic.</p>
              <p><NavLink to={MeanCertificate} target="_blank"><i class="fa fa-angle-right" aria-hidden="true"></i> View Certificate</NavLink></p>
            </div>
            <div className="eduinfo">
              <h4>React Native Mobile App - Udemy Course</h4>
              <h5>Jun 2019</h5>
              <p>I always wanted to build an mobile app, seached in Udemy for many days and finally found one great course which has 10 apps tutorials.</p>
            </div>
            <div className="eduinfo">
              <h4>BCOM in Kakatiya University</h4>
              <h5>2014 - 2018</h5>
            </div>
            <div className="eduinfo">
              <h4>Senior Secondary in AICOE</h4>
              <h5>2012 - 2014</h5>
            </div>
            <div className="eduinfo">
              <h4>SSC in The Guntur Public School</h4>
              <h5>2011 - 2012</h5>
            </div>
          </div>
        </div>

        <div className="experience">
          <div className="container">
            <h2>Work experience</h2>
            <div className="work">
              <div className="employer">
                <h3>Janes Defence India LLP</h3>
              </div>
              <div className="workInfo">
                <h4>Creative Front End Developer</h4>
                <h5>Oct 2020 - Present</h5>
                <p>Currently working as a Creative Front End Developer with Jane's Defence. Janes is a trusted source for defence intelligence - Unrivalled defence and security analysis.</p>
              </div>
            </div>
            <div className="work">
              <div className="employer">
                <h3>Gensuite LLC</h3>
              </div>
              <div className="workInfo">
                <h4>Lead Web Developer</h4>
                <h5>Sep 2018 - Sep 2020</h5>
                <p>Gensuite is a leading, cloud-based EHS software solutions provider that enables diverse companies to foster safe & sustainable workplaces worldwide.</p>
              </div>
            </div>
            <div className="work">
              <div className="employer">
                <h3>Lamppost media</h3>
              </div>
              <div className="workInfo">
                <h4>Web Designer and Developer</h4>
                <h5>June 2017 - Sep 2018</h5>
                <p>I worked for Lamppost 16 months as a Web designer and developer, Lamppost media pvt ltd is a a digital marketing agency that's known for creating success stories.</p>
              </div>
            </div>
            <div className="work">
              <div className="employer">
                <h3>Floret media</h3>
              </div>
              <div className="workInfo">
                <h4>Web Developer</h4>
                <h5>Dec 2015 - June 2017</h5>
                <p>Floret Media is a complete provider of the solutions you need to not only launch your business on the Internet, but to grow and maintain a distinctive and highly visible on-line presence.</p>
              </div>
            </div>
            <div className="work">
              <div className="employer">
                <h3>Ebaraha</h3>
              </div>
              <div className="workInfo">
                <h4>Web Developer</h4>
                <h5>Nov 2015 - Dec 2015</h5>
                <p>E BARAHA focus on Web Development Company. Web Design, Web Development & SEO Services Service Provider.</p>
              </div>
            </div>
            <div className="work">
              <div className="employer">
                <h3>Digiknots</h3>
              </div>
              <div className="workInfo">
                <h4>Web Designer</h4>
                <h5>Sep 2013 - Sep 2015</h5>
                <p>I started my Web developer career in Digiknots web enabled services pvt ltd as a junior web developer located in Hyderabad.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="portfolio">
          <div className="container">
              <h2>My Work</h2>
              <div className="project">
                  <figure>
                      <NavLink to="/project/guru-prasadam"><img src={require('../images/portfolio/guru-prasadam.png')} alt="Siddharth Naidu's Portfolio US Cranberries" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>Guru Prasadam</h3>
                      <h4>Built with Opencart, HTML & CSS</h4>
                      <NavLink to="/project/guru-prasadam"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <div className="project">
                  <figure>
                      <NavLink to="/project/us-cranberries"><img src={require('../images/portfolio/us-cranberries.png')} alt="Siddharth Naidu's Portfolio US Cranberries" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>US Cranberries</h3>
                      <h4>Built with WordPress, HTML & CSS</h4>
                      <NavLink to="/project/us-cranberries"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <div className="project">
                  <figure>
                      <NavLink to="/project/itor"><img src={require('../images/portfolio/itor.png')} alt="Siddharth Naidu's Portfolio" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>ItoR Automation</h3>
                      <h4>Built with WordPress, HTML & CSS</h4>
                      <NavLink to="/project/itor"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <div className="project">
                  <figure>
                      <NavLink to="/project/kuberan-silks"><img src={require('../images/portfolio/kuberan-silks.png')} alt="Siddharth Naidu's Portfolio Kuberan Silks" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>Kuberan Silks</h3>
                      <h4>Built with Opencart, HTML & CSS</h4>
                      <NavLink to="/project/kuberan-silks"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <div className="project">
                  <figure>
                      <NavLink to="/project/bull-india"><img src={require('../images/portfolio/bull-india.png')} alt="Siddharth Naidu's Portfolio Bull India" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>Bull India</h3>
                      <h4>Built with PHP, HTML & CSS</h4>
                      <NavLink to="/project/bull-india"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <div className="project">
                  <figure>
                      <NavLink to="/project/karlsson-leather"><img src={require('../images/portfolio/karlsson-leather.png')} alt="Siddharth Naidu's Portfolio Karlsson Leather" /></NavLink>
                  </figure>
                  <figcaption>
                      <h3>Karlsson Leather</h3>
                      <h4>Built with WordPress, HTML & CSS</h4>
                      <NavLink to="/project/karlsson-leather"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
                  </figcaption>
              </div>

              <NavLink to="/portfolio/" className="btn"><i class="fa fa-briefcase" aria-hidden="true"></i> View All My Projects</NavLink>

          </div>
        </div>

        <div className="hobbies">
          <div className="container">
              <h2>My Hobbies</h2>
              <div className="hobbie">
                <figure>
                  <NavLink to="/photos"><img src={require('../images/hobbies/travelling.jpg')} alt="Siddharth Travelling" title="Siddharth Travelling" /></NavLink>
                </figure>
                <h3>Travelling</h3>
                <p>When i'm not creating websites, i love to travel.</p>
                <NavLink to="/photos/" className="btn"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
              </div>
              <div className="hobbie">
                <figure>
                  <NavLink to="/hobbie/photography"><img src={require('../images/hobbies/photography.jpg')} alt="Siddharth Photography" title="Siddharth Photography" /></NavLink>
                </figure>
                <h3>Photography</h3>
                <p>Having fun while capturing memories is my favorite thing.</p>
                <NavLink to="/hobbie/photography/" className="btn"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
              </div>
              <div className="hobbie">
                <figure>
                  <NavLink to="/hobbie/movies"><img src={require('../images/hobbies/movies.jpg')} alt="Siddharth Movies" title="Siddharth Movies" /></NavLink>
                </figure>
                <h3>Netflix Movies</h3>
                <p>Among all, the most common hobby i have Watching Netflix.</p>
                <NavLink to="/hobbie/movies/" className="btn"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
              </div>
              <div className="hobbie">
                <figure>
                  <NavLink to="/hobbie/cooking"><img src={require('../images/hobbies/cooking.jpg')} alt="Siddharth Cooking" title="Siddharth Cooking" /></NavLink>
                </figure>
                <h3>Cooking</h3>
                <p>Perfect for anybody who enjoys eating.</p>
                <NavLink to="/hobbie/cooking/" className="btn"><i class="fa fa-angle-right" aria-hidden="true"></i> Read more...</NavLink>
              </div>
          </div>
        </div>

      </div>

    )
  }

}

export default About;
