import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


class Vijayawada extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Vijayawada Trip</title>
        </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/photos.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            <h3>Vijayawada</h3>
            <h4>When i'm not creating websites, i love to travel.</h4>
        </div>

        <div className="photos">
            <div className="container">

                <div className="mainWrapper">
                    <img src={require('../../images/trips/vijayawada/siddharth-vijayawada-trip-05.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                    <img src={require('../../images/trips/vijayawada/siddharth-vijayawada-trip-06.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                    <img src={require('../../images/trips/vijayawada/siddharth-vijayawada-trip-01.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/vijayawada/siddharth-vijayawada-trip-02.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/vijayawada/siddharth-vijayawada-trip-03.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/vijayawada/siddharth-vijayawada-trip-04.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/vijayawada/siddharth-vijayawada-trip-07.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                    <img src={require('../../images/trips/vijayawada/siddharth-vijayawada-trip-08.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                    <img src={require('../../images/trips/vijayawada/siddharth-vijayawada-trip-09.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                    <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-07.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                    <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-11.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                    <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-12.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-13.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-14.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-15.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-17.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                    <img src={require('../../images/trips/amaravati/siddharth-amaravati-trip-18.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
                </div>

            </div>
        </div>

      </div>
    )
  }

}

export default Vijayawada;
