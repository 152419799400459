import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


class Yelahanka extends Component {

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
    return(
      <div className="app">

        <Helmet>
            <title>Yelahanka Trip</title>
        </Helmet>

        <div className="banner">
            <img src={require('../../images/banners/photos.jpg')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" />
            <h3>Yelahanka</h3>
            <h4>When i'm not creating websites, i love to travel.</h4>
        </div>

        <div className="photos">
          <div className="container">

                <div className="mainWrapper">
                      <img src={require('../../images/trips/yelahanka/siddharth-yelahanka-trip-01.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                      <img src={require('../../images/trips/yelahanka/siddharth-yelahanka-trip-02.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                      <img src={require('../../images/trips/yelahanka/siddharth-yelahanka-trip-03.png')} alt="Siddharth Naidu's Trips" title="Siddharth Naidu's Trips" className="full-width" />
                </div>

          </div>
        </div>

      </div>
    )
  }

}

export default Yelahanka;
